import axios, { AxiosError } from 'axios';
import { http } from '../http';

const url= process.env.REACT_APP_AXIOS_URL;

export type Credentials = {
    grant_type: string;
    username: string;
    password: String;
}

interface LoginResponse {
    data: LoginData;
}

export interface LoginData {
    token_type: string;
    access_token: string;
    expires_in: string;
    access_roles: string[];
    loginError?: any
}


const LOGIN_URL: string = '/api/Authenticate';
//manually turn off credentials
const LOGIN_CONFIG = { withCredentials: false };
/**
 * It is because Axios does not allow turn on and off interceptors
 * for specific calls. It is not possilbe to use http.ts. Hence for log
 * in we initiate another log in axios instance, all other request will be made
 * by http
 */
const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  };
const axiosInstance = axios.create({
    baseURL: url,
    headers,
    withCredentials: false,
  });
  //    baseURL: "https://goapi.uat.goannaag.net",

export const fetchToken = async (userCredentials: Credentials): Promise<LoginData> => {

    let token: LoginData = {
        token_type: '',
        access_token: '',
        expires_in: '',
        access_roles: []
    };

    try {
        const response: LoginResponse = await axiosInstance.post(LOGIN_URL, userCredentials, LOGIN_CONFIG);
        token = response?.data;
    }
    catch(e) {
        const err = e as any;
        token = {...token, loginError: err};
    }
    return token;
}