import React from 'react';
import useAuth from '../hooks/useAuth';
import { useLocation, Navigate } from 'react-router-dom';

export interface IAppProps {
    children: React.ReactNode
}

function ProtectedRoute({ children }: IAppProps) {
    const location = useLocation();

    const access_token: string = localStorage.getItem('access_token') ?? '';
    if (access_token === '') {
        return <Navigate to="/" replace state={{ from: location }} />
    }
    else return <>{children}</>
}

export default ProtectedRoute;