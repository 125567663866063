import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { Route, Routes, redirect } from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext/AuthContext';
import Login from '../pages/Login/login';
import ProtectedRoute from './ProtectedRoutes';
import Page from '../pages/page';
import { Popover } from '@mui/material';
import { useState } from 'react';
import { SnackbarProvider } from 'notistack';
import GoannaAlert from '../components/GoannaAlert/GoannaAlert';
import styles from './styles.module.css';
import { LoginWithToken } from './LoginWithToken';
import Error404 from '../pages/Error/404/404';
import { PrintDashboardnWithToken } from './PrintDashboardWithToken';


const env: string | undefined = process.env.NODE_ENV;

// const initialItems: Item[] = [
//   { id: 'item-1', content: 'Item 1' },
//   { id: 'item-2', content: 'Item 2' },
//   { id: 'item-3', content: 'Item 3' },
// ];

const initialItems = [
  {
    "id": 0,
    "content": "Schedule Calendar",
    "exist": true,
    "show": true
  },
  {
    "id": 1,
    "content": "Soil Total",
    "exist": true,
    "show": true
  },
  {
    "id": 2,
    "content": "Stress",
    "exist": true,
    "show": true
  },
  {
    "id": 3,
    "content": "Soil Moisture Profile and Rootzone",
    "exist": true,
    "show": true
  },
  {
    "id": 4,
    "content": "Location",
    "exist": true,
    "show": true
  },
  {
    "id": 5,
    "content": "Rainfall",
    "exist": true,
    "show": true
  },
  {
    "id": 6,
    "content": "Crop",
    "exist": true,
    "show": true
  },
  {
    "id": 7,
    "content": "Irrigation",
    "exist": true,
    "show": true
  },
  {
    "id": 8,
    "content": "Etc Crop Water Use",
    "exist": true,
    "show": true
  },
  {
    "id": 9,
    "content": "Cumulative Day Degrees",
    "exist": true,
    "show": true
  }
]

const TestComponent = () => {
  const [items, setItems] = useState(initialItems);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setItems(newItems);
  };

  return (
    <Popover open>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: 0 }}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                        color: 'white',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item.content}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </Popover>
  );
};

export function RouterComponent() {

  return (
    <SnackbarProvider maxSnack={4} Components={{
      success: GoannaAlert,
      error: GoannaAlert,
      warning: GoannaAlert
    }}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      classes={{
        root: styles.snackbarRoot
      }}
      preventDuplicate
    >
      <AuthProvider>
        <Routes>
          <Route path='*' element={<Error404 />} />
          <Route path='/' element={<Login />} />
          <Route path='/LoginFromV1' element={<LoginWithToken />} />
          <Route path='/FieldScreenshot' element={<PrintDashboardnWithToken />} />
          <Route path='/pages/*' element={
            <ProtectedRoute>
              <Page />
            </ProtectedRoute>
          } />
        </Routes>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default RouterComponent;