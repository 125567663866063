
import { EChartsOption } from 'echarts';
import React from 'react';
import { useSelector } from 'react-redux';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useEtcCropWaterUseContext from '../../../../../hooks/ContextHooks/useEtcCropWaterUse';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { useTooltipFormatterWithDateFormat, valueFormatter } from '../../../../../utils/Echarts';
import { getUnitName, valueFormatter as numericConversionValueFormatter, valueRounder } from '../../../../../utils/numericConversions';
import * as url from '../../../../../assets/images/MarkPoint/Balloon2.png'
import * as url3 from '../../../../../assets/images/MarkPoint/Balloon3.png'
import styles from './style.module.css';
import { DateTime } from 'luxon';
import { FieldDetailDataType } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import { http } from '../../../../../api/http';
import SatelliteImageDialog from './SatelliteImageDialog';
import moment from 'moment';
import { XAXisOption } from 'echarts/types/dist/shared';
import HelpIconButton from '../HelpIconButton/HelpIconButton';
export interface IEtcCropWaterUseProps {
}

export default function EtcCropWaterUse(props: IEtcCropWaterUseProps) {

    const { dateRange } = useFieldDetailContext();
    const { loading, EtcCropWaterUseGraphSeries } = useEtcCropWaterUseContext();
    const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();
    const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
    const length = numericSystem === "M" ? "mm" : "In";
    const decimals = numericSystem === "M" ? 2 : 1;

    const generateMarkPoints = (data: { [key: string]: number[] | null }): any[] => {
        const markPoints: any[] = [];

        for (const key in data) {
            const value = data[key];
            if (value !== null) {
                const [date] = value;
                markPoints.push({
                    name: date,
                    coord: value,
                    value: 'Img',
                    itemStyle: { color: 'green' },
                    symbol: `image://${url.default}`,
                    label: {
                        color: 'black',
                        offset: [0, -8],
                    },
                    symbolSize: 50,
                    symbolOffset: [-1, -25],
                    emphasis: {
                        disabled: true
                    }
                });
            }
        }

        return markPoints;
    };

    const lastDataIndex = EtcCropWaterUseGraphSeries?.EvapoTranspirationCumulative?.data.length - 2; //getting the second last index instead of the last
    const lastDataValue = EtcCropWaterUseGraphSeries?.EvapoTranspirationCumulative?.data[lastDataIndex] ?? null;
    const lastForecastIndex = EtcCropWaterUseGraphSeries?.Etc_Forecast_Cumulative?.data.length - 1;
    const lastForecastValue = EtcCropWaterUseGraphSeries?.Etc_Forecast_Cumulative?.data[lastForecastIndex] ?? null;
    const NDVIImageMarkpoints = generateMarkPoints(EtcCropWaterUseGraphSeries.NDVIDate);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedPoint, setSelectedPoint] = React.useState<any>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [winURL, setWinURL] = React.useState('');
    const onMarkPointClick = (params: any) => {
        if (params.value === 'Img' && params.data.name) {
            setSelectedPoint(params.data.name);
            setDialogOpen(true);
        }
    };

    const onCloseDialog = () => {
        setDialogOpen(false);
        setSelectedPoint(null);
    };

    React.useEffect(() => {
        if (selectedPoint !== null) {
            fetchData();
        }
    }, [selectedPoint])

    const fetchData = async () => {
        const protocol = window.location.protocol.indexOf("file") === 0 ? "https:" : window.location.protocol;
        const locid = FieldDetailContext.locationDevicesData.data?.data.value[0].LocationIDSatellite.toString();
        const FromDate = selectedPoint.substring(0, 10).replace(/-/gi, "") + "T000000";
        const ToDate = DateTime.fromISO(selectedPoint)
            .set({ hour: 23, minute: 59, second: 59 })
            .plus({ days: 1 })
            .toFormat('yyyyLLdd\'T\'HHmmss');
        const queryfilter = locid + "/" + FromDate + "/" + ToDate;
        const url = process.env.REACT_APP_AXIOS_URL + "/reportdata/SatelliteFieldData/GetForDateRange/" + queryfilter;

        try {
            setIsLoading(true);
            const response = await http.get(url);
            const dataarray = response.data;
            let dataitem: any = {
                imageID: "",
            };

            dataarray.forEach((item: any) => {
                if (item.DateTime.substring(0, 10) === selectedPoint.substring(0, 10)) {
                    dataitem = item;
                }
            });

            const ampersand = "&";
            let constructedWinURL = protocol + "//app.dev.goannaag.net/#views/polygonView.html?polysource=Location" + ampersand + "pmca=true" + ampersand + "eatt=FieldPolyCoords" + ampersand + "id=" + locid + ampersand + "viewheaderHide=true" + ampersand + "sdm=false" + ampersand + "sfm=false" + ampersand + "slo=true" + ampersand + "sta=m" + ampersand + "alsndvi=true" + ampersand + "alsnatural=true" + ampersand + "alskc=true" + ampersand + "sfoot=false" + ampersand + "sndvim=true" + ampersand + "ndvidate=" + selectedPoint + ampersand + "imageid=" + dataitem.ImageID;

            constructedWinURL = constructedWinURL.trim();
            const token = localStorage.getItem('access_token')?.replaceAll("\"", '').replaceAll("-", "");
            //   const token = security.getToken();
            if (token && token.length > 0) {
                constructedWinURL += ampersand + "imageid2=" + token;
            }

            setWinURL(constructedWinURL);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching satellite data:", error);
            setIsLoading(false);
        }
    };

    const columnNames = ['Etc Crop Water Use', 'Etc Cumulative', 'Etc Forecast', 'Etc Cumulative Forecast', 'Images'];
    const formatter = useTooltipFormatterWithDateFormat({
        [columnNames[0]]: getUnitName(numericSystem, "mm"),
        [columnNames[1]]: getUnitName(numericSystem, "mm"),
        [columnNames[2]]: getUnitName(numericSystem, "mm"),
        [columnNames[3]]: getUnitName(numericSystem, "mm"),
        [columnNames[4]]: getUnitName(numericSystem, "mm"),
    }, false);

    const [legendSelectionModel, setLegendSelectionModel] = React.useState<{ [x: string]: boolean; }>({
        'Images': true
    });

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            textStyle: {
                fontWeight: 'normal'
            },
            formatter
        },
        legend: {
            data: columnNames,
            selected: legendSelectionModel
        },
        color: [
            '#FF0000',
            '#A7194A',
            '#0B5234',
            '#5B22DF'
        ],
        dataZoom: {
            type: 'slider'
        },
        grid: {
            left: 90,
            right: 90,
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
            min: dateRange[0]?.toISODate(),
            max: dateRange[1]?.toISODate(),
            axisPointer: {
                label: {
                    formatter: function (param: any) {
                        const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
                        return moment(param.value).format(dateTimeFormat);
                    }
                }
            },
        } as XAXisOption,
        yAxis: [
            {
                name: `Etc Crop Water Use (${length})`,
                type: 'value',
                nameLocation: 'middle',
                nameGap: 40,
                nameTextStyle: {
                    fontSize: '1rem',
                    color: '#000000',
                },
                splitLine: {
                    show: false,
                }
            },
            {
                name: `Etc Cumulative (${length})`,
                type: 'value',
                nameLocation: 'middle',
                nameGap: 40,
                nameTextStyle: {
                    fontSize: '1rem',
                    color: '#000000',
                },
            },
        ],
        series: [
            {
                name: 'Etc Crop Water Use',
                type: 'line',
                data: EtcCropWaterUseGraphSeries?.EvapoTranspiration?.data ?? [],
                smooth: false,
                showSymbol: true,
                symbolSize: 0.1,
                symbol: 'circle',
                // itemStyle: {
                //     borderWidth: 2,
                //     borderColor: '#FFFFFF'
                // },
                emphasis: {
                    scale: 100,
                },
                tooltip: {
                    valueFormatter: (value: any) => {
                        return valueFormatter(value, length)
                    }
                }
            },
            {
                name: 'Etc Cumulative',
                type: 'line',
                data: EtcCropWaterUseGraphSeries?.EvapoTranspirationCumulative?.data ?? [],
                smooth: false,
                showSymbol: true,
                symbolSize: 0.1,
                symbol: 'circle',
                // itemStyle: {
                //     borderWidth: 2,
                //     borderColor: '#FFFFFF'
                // },
                emphasis: {
                    scale: 100,
                },
                yAxisIndex: 1,
                markPoint: {
                    data: lastDataValue ? [
                        {
                            name: 'Etc Cumulative markPoint',
                            coord: lastDataValue,
                            value: lastDataValue ? valueRounder(lastDataValue[1], decimals) as any : null,
                        },
                    ]
                        :
                        [],
                    label: {
                        formatter: '{c}',
                        color: 'black',
                        offset: [-1, -8],
                    },
                    symbol: `image://${url.default}`,
                    symbolSize: 60,
                    symbolOffset: [-1, -30],
                    symbolRotate: 0,
                    emphasis: {
                        disabled: true
                    }
                },
                tooltip: {
                    valueFormatter: (value: any) => {
                        return valueFormatter(value, length)
                    }
                },
            },
            {
                name: 'Etc Forecast',
                type: 'line',
                data: EtcCropWaterUseGraphSeries?.Etc_Forecast?.data ?? [],
                smooth: false,
                showSymbol: true,
                symbolSize: 0.1,
                symbol: 'circle',
                // itemStyle: {
                //     borderWidth: 2,
                //     borderColor: '#FFFFFF'
                // },
                emphasis: {
                    scale: 100,
                },
                label: {
                    show: true
                },
                lineStyle: {
                    type: 'dashed'
                },
                tooltip: {
                    valueFormatter: (value: any) => {
                        return valueFormatter(value, length)
                    }
                }
            },
            {
                name: 'Etc Cumulative Forecast',
                type: 'line',
                data: EtcCropWaterUseGraphSeries?.Etc_Forecast_Cumulative?.data ?? [],
                smooth: false,
                showSymbol: true,
                symbolSize: 0.1,
                symbol: 'circle',
                // itemStyle: {
                //     borderWidth: 2,
                //     borderColor: '#FFFFFF'
                // },
                emphasis: {
                    scale: 100,
                },
                yAxisIndex: 1,
                lineStyle: {
                    type: 'dashed'
                },
                markPoint: {
                    data: lastForecastValue ? [
                        {
                            name: 'Etc Cumulative Forecast markPoint',
                            coord: lastForecastValue,
                            value: lastForecastValue[1] ? valueRounder(lastForecastValue[1], decimals) as any : null,
                        },
                    ]
                        :
                        [],
                    label: {
                        formatter: '{c}',
                        color: 'black',
                        offset: [-1, -8],
                    },
                    symbol: `image://${url.default}`,
                    symbolSize: 60,
                    symbolOffset: [-1, -30],
                    symbolRotate: 0,
                    emphasis: {
                        disabled: true
                    }
                },
                tooltip: {
                    valueFormatter: (value: any) => {
                        return valueFormatter(value, length)
                    }
                }
            },
            {
                name: 'Images',
                type: 'line',
                data: EtcCropWaterUseGraphSeries?.EvapoTranspiration?.data ?? [],
                smooth: true,
                symbol: 'none',
                itemStyle: {
                    color: '#9BCFFA'
                },
                lineStyle: {
                    color: '#FF000000'
                },
                tooltip: {
                    show: false,
                },
                markPoint: {
                    data: NDVIImageMarkpoints,
                }
            },
        ]
    };

    return (
        <>
            <FieldDetailPaper width='100%' id="EtcCropWaterUseGraph">
                <FieldDetailHeader backgroundColor='#D9D7D780'>
                    <div className={styles.EtcCropWaterUseHeader}>
                        Etc Crop Water Use
                        <HelpIconButton
                            content={[
                                { title: "ETc", content: "\"ETc\" stands for \"crop evapotranspiration, \" which is a measure of the water used by a crop through both evaporation from the soil and transpiration through the plant's leaves. Evapotranspiration (ET): The combined process of water loss from the soil surface and transpiration from the plant leaves." },
                                { title: "Cumulative ETc", content: "Cumulative ETc (Evapotranspiration) is a measure of the total amount of water lost from a particular area over a certain period, typically measured in millimetres or inches. ETc is the combined amount of water lost through both evaporation from soil and transpiration by plants." },
                                { title: "Crop coefficient (Kc)", content: "A factor that relates the ETc of a specific crop to the reference evapotranspiration (ETo). It is a function of crop type, growth stage, and other factors. The Growth rate is automatically calculated using an algorithm and NDVI satellite imagery." },
                            ]}
                        />
                    </div>
                </FieldDetailHeader>
                <FieldDetailBody className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px' }} loading={loading}>
                    <ReactECharts onLegendChange={({ selected }: any) => { setLegendSelectionModel(selected) }} option={option} style={{ height: '500px', width: '100%' }} loading={loading} onEvents={{ 'click': onMarkPointClick }} />
                </FieldDetailBody>
            </FieldDetailPaper>
            <SatelliteImageDialog open={dialogOpen} onClose={onCloseDialog} winURL={winURL} isLoading={isLoading} />
        </>
    );
}