import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import useUserSettings from '../hooks/ApiHooks/useUserSettings';
import axios from 'axios';
import { useAppDispatch } from '../stores/hooks';
import { setUser, initialState } from '../stores/Slices/userSlice';

/** Takes in token from the URL as auth and roles in the get request
 * @returns JSX loading page
 * @example 
 * // go to this URL 
 * // http://localhost:3000/LoginFromV1?auth=*****&roles=Registered%20Users
 * // for testing it here auth is the token and roles are the roles.
 * 
 */
export function PrintDashboardnWithToken() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { GetUserFromGraphQl } = useUserSettings();

  const dispatch = useAppDispatch();

  const LogoutUser = () => {
    localStorage.clear();
    dispatch(setUser(initialState));
  };

  useEffect(() => {
    (async () => {
      const token = searchParams.get("auth") || undefined;
      const roles = searchParams.get("roles") || undefined;

      //v2 is logged in but v1 is not
      if ((!token || !roles) && localStorage.getItem("access_token")) return navigate("/pages/goField/fieldTablePrint");
      //v2 already logged in and also v1 is logged in 
      if (token && roles && localStorage.getItem("access_token")) {
        if (await validateToken(token))
          LogoutUser();
        else {
          return navigate("/pages/goField/fieldTablePrint");
        }
      };


      // if no token passed in, go to login
      if (!token) {
        sendToLogin(navigate);
      }

      // no roles go to login
      if (!roles || roles.length === 0) {
        sendToLogin(navigate);
        console.error("No roles given!");
        return;
      }

      // validate token if not valid go to login
      if (!await validateToken(token))
        sendToLogin(navigate);
      else {
        localStorage.setItem("access_token", "\"" + token + "\"");

        GetUserFromGraphQl(roles.split(",") ?? [], token)
          .then(() => {
            navigate("/pages/goField/fieldTablePrint");
          });
      }

    })();
  }, []);

  return (
    <div>Loading...</div>
  );
}

function sendToLogin(navigate: NavigateFunction) {
  localStorage.clear();
  setTimeout(() => {
    navigate("/");
  }, 200);
}

async function validateToken(token: string | undefined) {
  const url = process.env.REACT_APP_AXIOS_URL;

  const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Bearer " + token
  };

  const axiosInstance = axios.create({
    baseURL: url,
    headers,
  });

  try {
    const response = await axiosInstance.get("/odata/TimeZones");
    return response.status === 200;
  }
  catch {
    return false;
  }
}
