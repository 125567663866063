import axios, { AxiosError } from 'axios';
import { http } from '../http';

const SOILPROBES_PATH: string = 'odata/SoilProbesForV2';
const TIME_STAMP: number = Date.now();

export interface QueryStringParameters {
    format: string,
    top?: number,
    skip?: number,
    orderby?: string,
    filter?: string,
    count?: boolean
};

export interface FieldDataType {
    columns: any[];
    row: any[];
    count: number
  }

export interface OdataResponse {
    data: {
        '@odata.count': number;
        value: any[]
    }
}

export interface OdataReturnType {
    data: {
        count: number;
        value: any[]
    }
}

type ServerError = { errorMessage: string };


//for client side operations
export const getAllDevices = async (filter?: string) => {
    const initialQueryStringParameters: QueryStringParameters = {
      format: 'json',
      count: true,
      filter
    };

    const url: string = SOILPROBES_PATH + OdataQueryBuilder(initialQueryStringParameters);
    try {
        const response: OdataResponse = await http.get(url);
        const returnResponse: OdataReturnType = {
            data: {
                count: response.data['@odata.count'],
                value: response.data.value
            }
        }
        return returnResponse;
    }
    catch (e) {
        if (axios.isAxiosError(e)) {
            const serverError = e as AxiosError<ServerError>;
            // if (serverError && serverError.response) {
            //     /* console.error(serverError.response.data); */
            // }
        }
        const returnResponse: OdataReturnType = {
            data: {
                count: 0,
                value: []
            }
        };
        /* console.error({errorMessage: 'Something whent wrong while requesting all device list.'}); */
        return returnResponse;
    }
}

function OdataQueryBuilder(OdataQuery: QueryStringParameters) {
    let query: string = '?';

    Object.entries(OdataQuery).forEach(([key, value]) => {
        if (value !== '' && (value !== undefined || value === 0)) {
            const encodedValue: string = encodeURIComponent(value);
            query += `$${String(key)}=${String(encodedValue)}&`;
        } 
      });
      
      query += `_=` + TIME_STAMP;
      const encodedQuery = encodeURIComponent(query);
      return query;
}

//for server side operations
export const getDevicesPerPage = async (parameter: QueryStringParameters) => {
    const queryObj: QueryStringParameters = {
        format: parameter.format,
        top: parameter.top,
        skip: parameter.skip,
        orderby: parameter.orderby,
        filter: parameter.filter,
        count: parameter.count
    }

    const url: string = SOILPROBES_PATH + OdataQueryBuilder(queryObj);

    try {
        const response: OdataResponse = await http.get(url);
        const returnResponse: OdataReturnType = {
            data: {
                count: response.data['@odata.count'],
                value: response.data.value
            }
        }
        return returnResponse;
    }
    catch (e) {
        if (axios.isAxiosError(e)) {
            const serverError = e as AxiosError<ServerError>;

        }
        const returnResponse: OdataReturnType = {
            data: {
                count: 0,
                value: []
            }
        };
        /* console.error({errorMessage: 'Something whent wrong while requesting all device list.'}); */
        return returnResponse;
    }
}
