import InputNumberInterval from '../pages/Dashboard/FieldTable/filterOperator/InputNumberInterval';
import { GridCellParams, GridFilterInputMultipleValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';
import moment from 'moment';
import { ChangeEventHandler } from 'react';
import styles from './style.module.css';
import { getAllDevices, getDevicesPerPage, QueryStringParameters } from '../api/services/device';
import { FieldDataType } from '../api/services/device';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ImperialToMetric, valueRounder } from '../utils/numericConversions';
import { DateTime } from 'luxon';
import { AEST_Zone, CURRENT_TIME_AEST, aestToLocationLocalTimeConverter, tileArrayGenerator } from '../utils/DateConvertor';
import { FormControl, InputLabel, Link, NativeSelect } from '@mui/material';

const useFieldTableData = () => {
    const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);

    // const [row, setRow] = React.useState<any[]>([]);
    const numberOnlyOperators: GridFilterOperator[] = [
        {
            label: 'Between',
            value: 'between',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
                    return null;
                }
                if (filterItem.value[0] == null || filterItem.value[1] == null) {
                    return null;
                }

                
                return ({ value }) => {
                    return (
                        value !== null &&
                        filterItem.value[0] <= value &&
                        value <= filterItem.value[1]
                    );
                };
            },
            InputComponent: InputNumberInterval,
        }
    ];

    // const mmFormatter = (params: { value: number | null }): number | null => numericSystem == "M" ? params.value : mmToInConverter(params.value);


    const activeColumnOperators: GridFilterOperator[] = [
        {
            label: 'Is',
            value: 'is',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (filterItem.value === undefined || filterItem.value === null) {
                    return null;
                }

                const valueAsBoolean = filterItem.value === 1;

                return (params: any) => {
                    return params.value === valueAsBoolean;
                };
            },
            InputComponent: ({ item, applyValue }: GridFilterInputMultipleValueProps) => {
                const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = Number(event.target.value);
                    if (value === -1)
                        applyValue({ ...item, value: undefined });
                    else
                        applyValue({ ...item, value });
                };
                const Options = [
                    { text: 'Any', value: -1 },
                    { text: 'Yes', value: 1 },
                    { text: 'No', value: 0 },
                ];
                return <FilterDropdown Options={Options} item={item} handleFilterChange={handleFilterChange} />;
            },
        },
    ];

    const todayColumnOperators: GridFilterOperator[] = [
        {
            label: 'Is',
            value: 'is',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (filterItem.value === undefined || filterItem.value === null) {
                    return null;
                }
                const valueAsBoolean = filterItem.value === 1;

                return (params: any) => {
                    const irrigationDueForecast = params.value;
                    const forecastDate = irrigationDueForecast ?
                        DateTime.fromISO(irrigationDueForecast, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference })
                        : null;
                    const startingTime = CURRENT_TIME_AEST.plus({ hour: params.row.GMTDifference });
                    const renderList = tileArrayGenerator(startingTime, forecastDate);
                    return renderList[0] === filterItem.value;
                };
            },
            InputComponent: ({ item, applyValue }: GridFilterInputMultipleValueProps) => {
                const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = Number(event.target.value);
                    if (value === -1)
                        applyValue({ ...item, value: undefined });
                    else
                        applyValue({ ...item, value });
                };
                const Options = [
                    { text: 'Any', value: -1 },
                    { text: 'OK', value: 0 },
                    { text: 'Irrigate', value: 1 },
                    { text: 'Overdue', value: 2 },
                    { text: 'N/A', value: 3 }
                ];
                return <FilterDropdown Options={Options} item={item} handleFilterChange={handleFilterChange} />;
            },
        },
    ];

    const FilterDropdown = (props: { Options: { text: string, value: number }[], item: GridFilterItem, handleFilterChange: ChangeEventHandler<HTMLSelectElement> }) => {
        return (
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="filter-select">
                    Value
                </InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'filterValue',
                        id: 'filter-select',
                    }}
                    defaultValue={props.item.value === undefined ? -1 : props.item.value}
                    onChange={props.handleFilterChange}
                >
                    {
                        props.Options.map((option) => {
                            return <option value={option.value}>{option.text}</option>
                        })
                    }
                </NativeSelect>
            </FormControl>
        );
    };

    const irrigationForecastSort = (a: any, b: any, cellParams1: any) => {

        const sortModel = cellParams1.api.getSortModel();

        if (sortModel[0].sort == "asc") {
            if (a === null) {
                return 1;
            }
            if (b === null) {
                return -1;
            }
        } else {
            if (a === null) {
                return -1;
            }
            if (b === null) {
                return 1;
            }
        }
        // return (a || '').localeCompare((b || ''));
        if (a instanceof Date || b instanceof Date) {
            return DateTime.fromJSDate(a).toMillis() - DateTime.fromJSDate(b).toMillis();
        }
        return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
        // a instanceof Date || b instanceof Date ?
        // DateTime.fromJSDate(a).toMillis() - DateTime.fromJSDate(b).toMillis()
        // :
        // DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
    }
    const columns = [
        {
            field: "LocationDescription",
            type: 'string',
            headerName: 'Field',
            width: 250,
            editable: false,
            renderCell: (params: any) => (
                <FieldCell params={params} />
            )
        },
        {
            field: 'currentStatus',
            headerName: 'Today',
            disableExport: true,
            width: 130,
            type: 'string',
            valueGetter: (params: any) => params.row.IrrigationDueForecast,// ? DateTime.fromISO(params.row.IrrigationDueForecast, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference }) : null,
            renderCell: (params: any) => (
                <CurrentStatusCell params={params} />
            ),
            sortComparator: irrigationForecastSort,
            filterOperators: todayColumnOperators,
        },
        {
            field: 'IrrigationDueForecast',
            headerName: 'Forecast',
            disableExport: true,
            width: 170,
            type: 'string',
            valueGetter: (params: any) => params.row.IrrigationDueForecast,// ? DateTime.fromISO(params.row.IrrigationDueForecast, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference }) : null,
            renderCell: (params: any) => (
                <ForecastCell params={params} />
            ),
            sortComparator: irrigationForecastSort,
            filterable: false,
        },
        {
            field: 'IrrigationDueActual',
            headerName: 'Irrigation Forecast',
            type: 'dateTime',
            width: 170,
            valueGetter: (params: any) => params.row.IrrigationDueForecast ? DateTime.fromISO(params.row.IrrigationDueForecast, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference }) : null,
            sortComparator: irrigationForecastSort,
            valueFormatter: (params: any) => {
                return dateFormatter({
                    value: params.value ? params.value.toISO() : null
                }, false)
            },
        },
        {
            field: 'Active',
            headerName: 'Active',
            type: 'boolean',
            valueGettter: (params: any) => activeStatusFormatter(params),
            valueFormatter: (params: any) => activeStatusFormatter(params),
            filterOperators: activeColumnOperators
        },
        // { field: 'Yield', headerName: `Yield Loss (${getUnitName(numericSystem, "b/ha")})`, filterOperators: numberOnlyOperators },
        {
            field: 'DateTime',
            headerName: 'Last Update',
            type: 'dateTime',
            width: 170,
            valueGetter: (params: any) => params.value,
            valueFormatter: (params: any) => dateFormatter(params),
            cellClassName: (params: GridCellParams<string>) => changeCellClassName(params),
            sortComparator: irrigationForecastSort
        },
        { field: 'TimeZoneCode', headerName: 'Time Zone', type: 'string' },
        { field: 'Fullpoint', headerName: `Full Point`, filterOperators: numberOnlyOperators },
        { field: 'Refillpoint', headerName: `Refill Point`, filterOperators: numberOnlyOperators },
        { field: 'SoilTotal', headerName: `Current`, filterOperators: numberOnlyOperators },
        { field: 'DailyUse', headerName: `Current Usage`, filterOperators: numberOnlyOperators },
        { field: 'DayWaterUse', headerName: `ETc`, filterOperations: numberOnlyOperators },
        {
            field: 'TotalWaterUse', headerName: `Cumulative ETc`,
            valueGetter: (params: any) => {
                // for double conversion
                return valueRounder((numericSystem === "I" ? ImperialToMetric(params.value, "In") : params.value), 1)
            },
            filterOperators: numberOnlyOperators
        },
        {
            field: 'DayDegrees', headerName: "Total Day Degrees",
            valueGetter: (params: any) => numericSystem === "I" ? valueRounder(params.value, 0) : params.value,
            filterOperators: numberOnlyOperators
        },
        {
            field: 'StartDate',
            headerName: 'Start Date',
            type: 'date',
            width: 170,
            valueGetter: (params: any) => params.row.StartDate ? DateTime.fromISO(params.row.StartDate, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference }) : null,
            sortComparator: irrigationForecastSort,
            valueFormatter: (params: any) => {
                return dateFormatter({
                    value: params.value ? params.value.toISO() : null
                }, true)
            },
        },
        {
            field: 'PlantingDate',
            headerName: 'Planting Date',
            type: 'date',
            width: 170,
            valueGetter: (params: any) => params.row.PlantingDate ? DateTime.fromISO(params.row.PlantingDate, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference }) : null,
            sortComparator: irrigationForecastSort,
            valueFormatter: (params: any) => {
                return dateFormatter({
                    value: params.value ? params.value.toISO() : null
                }, true)
            },
        },
        { field: 'FarmName', headerName: 'Farm', width: 130 },
        { field: 'RegionName', headerName: 'Region', width: 130 },
        { field: 'CropName', headerName: 'Crop', width: 95 },
        { field: 'VarietyName', headerName: 'Variety', width: 110 },
        { field: 'AccountName', headerName: 'Account Name', width: 95 },
        { field: 'DefaultUsage', headerName: 'Default Usage', width: 95 },
        {
            field: 'IrrigationDueDefault',
            headerName: 'Irrigation Forecast (Default)',
            type: 'dateTime',
            width: 170,
            valueGetter: (params: any) => params.row.IrrigationDueDefault ? DateTime.fromISO(params.row.IrrigationDueDefault, { zone: AEST_Zone }).plus({ hour: params.row.GMTDifference }) : null,
            sortComparator: irrigationForecastSort,
            valueFormatter: (params: any) => {
                return dateFormatter({
                    value: params.value ? params.value.toISO() : null
                })
            },
        },
    ];

    const changeCellClassName = (params: GridCellParams<string>) => {
        if (params.value == null) {
            return '';
        }

        const currentDate = DateTime.local({ zone: AEST_Zone });
        const cellDate = DateTime.fromISO(params.value, { zone: AEST_Zone }).minus({ hours: params.row.GMTDifference });

        return clsx('super-app', {
            overOneDay: currentDate.diff(cellDate, 'days').days > 1,
            underOneDay: currentDate.diff(cellDate, 'days').days <= 1,
        });
    };

    const convertToDate = (params: any) => {
        return params.value && new Date(params.value);
    }

    const dateFormatter = (params: any, dateOnly: boolean = false) => {
        if (dateOnly) {
            if (params.value) {
                const parsedDate = DateTime.fromISO(params.value, { setZone: true });
                const dateFormat = numericSystem == "M" ? "dd/MM/yyyy" : "MM/dd/yyyy";
                return parsedDate.toFormat(dateFormat);
            }
        }
        else if (params.value) {
            const parsedDate = DateTime.fromISO(params.value, { setZone: true });
            const dateFormat = numericSystem == "M" ? "dd/MM/yyyy" : "MM/dd/yyyy";
            const format = dateFormat + " HH:mm";
            return parsedDate.toFormat(format);
        }
        else return '';
    }

    const activeStatusFormatter = (params: any) => {
        return params.value ? 'Yes' : 'No'
    }

    let row: any[] = [];
    let count: number = 0;
    const data: FieldDataType = { columns, row, count };

    //for server side data operations
    async function getDevices(parameters: QueryStringParameters) {
        const response = await getDevicesPerPage(parameters).then((result) => {
            data.row = result.data.value;
            data.count = result.data.count;
        });
        return data;
    }

    //for client side data operations
    async function getAllUserDevices(filter? :string) {
        
        await getAllDevices(filter).then((result) => {
            data.row = result.data.value;
            data.count = result.data.count;
        });
        return data;
    }


    // return getDevices;
    //getDevices is temporarily disable because the backend hasn't implemented features that support filtering, getAllUserDevices will retrieve all the data at once and process it on the client side
    return getAllUserDevices;
}

const CurrentStatusCell = (props: { params: any }) => {
    const irrigationDueForecast = props.params.row.IrrigationDueForecast;
    const forecastDate = irrigationDueForecast ? aestToLocationLocalTimeConverter(irrigationDueForecast, props.params.row.GMTDifference, true) : null;
    const startingTime = CURRENT_TIME_AEST.plus({ hour: props.params.row.GMTDifference });
    const renderList = tileArrayGenerator(startingTime, forecastDate);
    let color, text;
    switch (renderList[0]) {
        case 0:
            color = '#2D9C41';
            text = 'OK';
            break
        case 1:
            color = '#1085FD';
            text = 'Irrigate';
            break
        case 2:
            color = '#E22C29';
            text = 'Overdue';
            break
        case 3:
            color = '#979797';
            text = 'N/A';
            break
    }
    return <div style={{
        textAlign: 'center'
    }}>
        <span className={styles.dot} style={{
            marginLeft: '1rem',
            backgroundColor: color
        }}></span>
        <span style={{
            marginLeft: '1rem'
        }}>{text}</span>
    </div>
}

const ForecastCell = (props: { params: any }) => {
    const irrigationDueForecast = props.params.row.IrrigationDueForecast;
    const forecastDate = irrigationDueForecast ? aestToLocationLocalTimeConverter(irrigationDueForecast, props.params.row.GMTDifference, true) : null;
    const startingTime = CURRENT_TIME_AEST.plus({ hour: props.params.row.GMTDifference });
    const renderList = tileArrayGenerator(startingTime, forecastDate);
    renderList.shift();
    return <div style={{
        textAlign: 'center'
    }}>
        {
            renderList.map((c, i) => {
                switch (c) {
                    case 0:
                        return <span key={props.params.row.LocationID + "green" + i} className={styles.dot} style={{ backgroundColor: '#2D9C41' }}></span>
                    case 1:
                        return <span key={props.params.row.LocationID + "blue" + i} className={styles.dot} style={{ backgroundColor: '#1085FD' }}></span>
                    case 2:
                        return <span key={props.params.row.LocationID + "red" + i} className={styles.dot} style={{ backgroundColor: '#E22C29' }}></span>
                    case 3:
                        return <span key={props.params.row.LocationID + "gray" + i} className={styles.dot} style={{ backgroundColor: '#979797' }}></span>
                }
            })
        }
    </div>
}


export const FieldCell = (props: { params: any }) => {

    return <Link
        sx={{
            color: "#0958FF",
            font: "normal normal normal 14px/20px Poppins",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            }
        }}
        href={`/pages/goField/fields/${String(props.params.row.LocationID)}`}
        target="_blank"
        rel="noopener noreferrer"
    >
        <div>{props.params.row.LocationDescription}</div>
    </Link>
}

export default useFieldTableData;