import * as React from 'react';
import { useSelector } from 'react-redux';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { gql, useLazyQuery } from '@apollo/client';
import { aestToLocationLocalTimeConverter } from '../../utils/DateConvertor';

interface RowDataGraphSeriesDataType {
  series: {
    Volts: {
        type: any;
        name: string;
        data: any[];
    };
    WaterLevel: {
        type: any;
        name: string;
        data: any[];
    };
    RawDataMillivolt: {
        type: any;
        name: string;
        data: any[];
    };
}
};

interface RowContextDataType {
  RowDataGraphSeries: RowDataGraphSeriesDataType | null,
  loading: boolean
}

interface IRoweProviderProps {
  children: React.ReactNode,
}

const RowContext = React.createContext({} as RowContextDataType);

const GET_ROW_DATA = gql`
    query ($locationId: Int!, $startDate: String!, $endDate: String!) {
      rowData(where: { locationId: $locationId, startDate: $startDate, endDate: $endDate }) {
        data {
          dateTime
          volts
          waterLevel
          rawDataMillivolt
        }
      }
    }
  `

export default function RowProvider(props: IRoweProviderProps) {
  const { locationID, soilProbesData, dateRange } = useFieldDetailContext();
  const GMTdifferenceToAEST = soilProbesData.data?.data?.value[0]?.GMTDifference;

  const [getRowData, { loading, data }] = useLazyQuery(GET_ROW_DATA, {
    fetchPolicy: 'cache-first',
    variables: {
      'locationId': locationID,
      'startDate': dateRange[0]?.plus({ hours: GMTdifferenceToAEST }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
      'endDate': dateRange[1]?.plus({ hours: GMTdifferenceToAEST }).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
    }
  });
  const [RowDataGraphSeries, setRowDataGraphSeries] = React.useState<RowDataGraphSeriesDataType | null>(null);
  const isFetchingRef = React.useRef<boolean>(false)

  React.useEffect(() => {
    if (!locationID || !dateRange[0] || isFetchingRef.current) return;
    if (data || loading) return;
    isFetchingRef.current = true;
    getRowData()
    .then(() => isFetchingRef.current = false)
    .catch(err => console.error("Error fetching row data:", err));
  }, [locationID, dateRange]);


  React.useEffect(() => {
    if (!data || GMTdifferenceToAEST === undefined || GMTdifferenceToAEST === null) return;
    const series = {
      Volts: {
        type: 'line' as any,
        name: 'Volts',
        data: [] as any[]
      },
      WaterLevel: {
        type: 'line' as any,
        name: 'WaterLevel',
        data: [] as any[]
      },
      RawDataMillivolt: {
        type: 'line' as any,
        name: 'WaterLevel',
        data: [] as any[]
      }
    };
    let prevDateTime: string = '';
    data.rowData.data.forEach((element: any) => {
      if (element.dateTime) {
        //skip duplicated data
        if (prevDateTime === element.dateTime) return;
        //change to location local time for display
        const dateTime = aestToLocationLocalTimeConverter(element.dateTime, GMTdifferenceToAEST)
        if (element.volts) {
          series.Volts.data.push([dateTime, element.volts]);
        }
        if (element.waterLevel) {
          series.WaterLevel.data.push([dateTime, element.waterLevel]);
        }
        if (element.rawDataMillivolt) {
          series.RawDataMillivolt.data.push([dateTime, element.rawDataMillivolt]);
        }
        prevDateTime = element.dateTime;
      }
    });
    setRowDataGraphSeries({ series });

  }, [data, GMTdifferenceToAEST]);

  return (
    <RowContext.Provider value={{ RowDataGraphSeries, loading: loading }}>
      {props.children}
    </RowContext.Provider>
  );
}

export { RowContext, RowProvider };
