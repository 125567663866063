import React, { useEffect } from 'react';
import { Alert, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import styles from '../styles.module.css';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { http } from '../../../api/http';
import { Credentials, LoginData } from '../../../api/services/login';
import useAuth from '../../../hooks/useAuth';
import { UpdateTabTitle } from '../../../utils/updateTabTitle';
import { loginFormSchema as validationSchema } from '../../../utils/validations';


export interface IAppProps {
  showResetPasswordUI: () => void
  className: string
  loading: boolean
}

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontFamily: 'Poppins'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          background: 'linear-gradient(to right, #75B9F0, #1085FD)'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          marginRight: "7px",
          color: '#333',
        },
      },
    },
  }
});

function LoginCard(props: IAppProps) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  UpdateTabTitle('Log in')
  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setLoading(true)
      // validation if the token is still valid
      http.get("api/userProfileDetailed/userProfileDetailed")
        .then(() => {
          setLoading(false)
          navigate("/pages/dashboard", { replace: true })
        })
    }
  }, [navigate])


  const { onLogin, token, isV2User } = useAuth();

  React.useEffect(() => {
    if (token.loginError) {
      setLoading(false);
    }
    if (isV2User !== null) {
      setLoading(false);
    }
  }, [token.loginError, isV2User]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },

    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      const userCredentials: Credentials = {
        grant_type: "password",
        username: values.email,
        password: values.password
      };
      setLoading(true);
      onLogin(userCredentials);
    }
  });
  const errorTypes = {
    '401': 'Email Or Password Is Invalid',
    '403': 'Error: Access forbidden',
    '425': 'Error: Too many Requests',
    '500': 'Error: Internal ServerError'
  };

  const [value, setValues] = React.useState({
    showPassword: false,
  });

  function handlePasswordVisibilityChange() {
    setValues({
      ...value,
      showPassword: !value.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const { t } = useTranslation("Login");

  const errorMessage = (token: LoginData, isV2user: boolean | null) => {
    if (token.loginError) {
      return <ThemeProvider theme={theme}>
        <Alert style={{ paddingLeft: "11.34px" }} severity='error'>
          {
            token.loginError.response.status !== undefined && token.loginError.response.status !== 0
              ? `${errorTypes[token.loginError.response.status as keyof typeof errorTypes]}: ${token.loginError.response.status}`
              : 'Something went wrong, please try again later.'
          }
        </Alert>
      </ThemeProvider>
    }

    if (isV2user === false) {
      return <Alert severity='error'>
        {
          <span style={{
            display: 'block',
            textAlign: 'left'
          }}>Access Denied: Please contact <a href='mailto: help@goannaag.com.au' style={{ color: '#0958FF' }}>help@goannaag.com.au</a></span>
        }
      </Alert>
    }
    return undefined;
  };

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{
        borderRadius: '10px',
        boxShadow: 0.5
      }}
        className={props.className} variant='outlined'
      >

        <CardHeader className={styles.cardHeader} sx={{ paddingBottom: 0 }} title={t("Login-login")} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent className={styles.cardContent} sx={{ marginBottom: 0 }}>
            <div className={styles.loginTextField}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Username"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className={styles.loginTextField}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={value.showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handlePasswordVisibilityChange}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {value.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
            </div>
            {
              errorMessage(token, isV2User) !== undefined ?
                <div className={styles.loginTextField}>
                  {errorMessage(token, isV2User)}
                </div>
                :
                undefined
            }
            <div className={styles.loginTextField} style={{ textAlign: "right" }}>
              <Link href='#' style={{ fontSize: "15px", textDecoration: "none" }} onClick={() => props.showResetPasswordUI()}>Forgot Password?</Link>
            </div>
            <div className={styles.loginTextField}>
              <Button color="primary" variant="contained" fullWidth type="submit" disableElevation sx={{
                "&:hover": {
                  boxShadow: 3,
                }
              }}
                size='large'
                disabled={loading}
              >
                {
                  loading ?
                    <CircularProgress
                      size={25}
                      sx={{
                        color: 'white'
                      }}
                    />
                    :
                    <span>{t("Login-login")}</span>
                }
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </ThemeProvider>
  );
}

export default LoginCard;

