import { Box, Slider } from '@mui/material';
import React, { useState } from "react";
import GoannaButton from '../GoannaButton/GoannaButton';
import styles from './style.module.css';
import useCanopyStressContext from '../../hooks/ContextHooks/useCanopyStressContext';
import usePutLocationsEdit, { LocationsEditDataType } from '../../hooks/ApiHooks/useLocationsEdit';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { useSnackbar } from 'notistack';
import useUpdateCanopyForecast from '../../hooks/ApiHooks/useUpdateCanopyForecast';

export interface IrrigationThresholdSliderProps {
}

export default function IrrigationThresholdSlider(props: IrrigationThresholdSliderProps) {

	const { irrigationThreshold, setIrrigationThreshold } = useCanopyStressContext();
	const [previrrigationThreshold, setPrevIrrigationThreshold] = React.useState(irrigationThreshold);
	const [loading, setLoading] = React.useState(false);
	const { LocationsEdit, soilProbesData } = useFieldDetailContext();
	const LocationsEditData = LocationsEdit.data?.data.value[0] || {} as LocationsEditDataType;
	const { enqueueSnackbar } = useSnackbar();
	const [putLocationEditData, { error }] = usePutLocationsEdit();
	const mutation = useUpdateCanopyForecast();

	const handleSliderValuChange = (newValue: number | number[]) => {
		if (Array.isArray(newValue)) {
			setIrrigationThreshold(newValue[0]);
		} else {
			setIrrigationThreshold(newValue);
		}
	}

	const handleSave = () => {
		setLoading(true);
		putLocationEditData(
			{ CanopyIrrigationThreshold: irrigationThreshold.toString() },
			LocationsEditData,
			async () => {
				enqueueSnackbar({
					variant: "success",
					message: "Canopy irrigation threshold saved!",
					autoHideDuration: 5000
				});
				setLoading(false);
				setPrevIrrigationThreshold(irrigationThreshold);
				await mutation.mutateAsync(LocationsEditData?.LocationID)
				enqueueSnackbar({
					variant: "success",
					message: "Irrigation forecast updated!",
					autoHideDuration: 5000
				});
				//update the locationsEdit
				await LocationsEdit.refetch();
				await soilProbesData.refetch();
			},
			(e) => {
				setIrrigationThreshold(previrrigationThreshold);
				setLoading(false);
				enqueueSnackbar({
					variant: "error",
					message: "Error: failed to save Canopy irrigation threshold.",
					autoHideDuration: 5000
				});
				console.error(e);
			}
		);
	};
	

	return (
		<span className={styles.stressPercent}>
			<span className={styles.irrigationThresholdText}>Irrigation Threshold: </span>
			<Box sx={{
				position: "relative",
				marginRight: '15px',
				marginTop: '25px',
			}}>
				<Slider
					value={irrigationThreshold}
					onChange={(e, newValue) => handleSliderValuChange(newValue)}
					step={1}
					marks={[
						{ value: 1, label: "1%" },
						{ value: 150, label: "150%" },
					]}
					min={1}
					max={150}
					valueLabelDisplay="auto"
					valueLabelFormat={(value) => `${value}%`}
					sx={{
						width: '200px',
						"& .MuiSlider-track": {
							background: '#2D9C41',
							border: "none",
						},
						"& .MuiSlider-rail": {
							background: "linear-gradient(to right, #75B9F0, #1085FD)",
							opacity: 1,
						},
						"& .MuiSlider-thumb": {
							backgroundColor: "#FFFFFF",
							width: 18,
							height: 18,
							"&:hover, &.Mui-focusVisible": {
								boxShadow: "0px 0px 10px #1085FD", // Green glow on hover/focus
							},
						},
						"& .MuiSlider-valueLabel": {
							position: "absolute",
							top: "3px",
							opacity: 0, // Hide by default
							transition: "opacity 0.2s ease-in-out",
						},
						"&:hover .MuiSlider-valueLabel": {
							opacity: 1,
						},
					}}
				/>
			</Box>
			<GoannaButton
				title='save'
				width='150px'
				height='42px'
				disabled={irrigationThreshold === previrrigationThreshold}
				loading={loading}
				onClick={handleSave}
			/>
		</span>
	);
}
