import { useMutation } from "@tanstack/react-query";

const API_URL = process.env.REACT_APP_GQL_V2_URL as string;
const AUTH_TOKEN = localStorage.getItem('access_token')?.replaceAll("\"", '')

async function updateCanopyForecast(locationId: number) {
  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          updateCanopyForecast(LocationID: ${locationId}) {
            CurrentCanopyStress
            CanopyDeficitStressForecast
            CanopyIrrigationDate
            Threshold
          }
        }
      `,
      variables: {},
    }),
  });

  const result = await response.json();

  if (!response.ok || result.errors) {
    throw new Error(result.errors ? result.errors[0].message : "API request failed");
  }

  return result.data.updateCanopyForecast;
}

export default function useUpdateCanopyForecast() {
  return useMutation({
    mutationFn: updateCanopyForecast,
    onSuccess: (data) => {
      console.log("Update successful:", data);
    },
    onError: (error) => {
      console.error("Update failed:", error);
    },
  });
}