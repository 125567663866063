import { DateTime } from 'luxon';
export const sortConst = {
    Irrigation_Forecast_Date_ASC: "1",
    Irrigation_Forecast_Date_DESC: "2",
    Field_Name_ASC: "3",
    Field_Name_DESC: "4",
    Crop_ASC: "5",
    Crop_DESC: "6",
    Irrigation_Override_Date_ASC: "7",
    Irrigation_Override_Date_DESC: "8",
}
export const sortIrrigationForecastItem = (IrrigationForecastArray: any[], sort: string) => {

    let tmp = [];
    switch (sort) {
        case sortConst.Irrigation_Forecast_Date_ASC:
            IrrigationForecastArray.sort((a, b) => {
                if (a.irrigationForecastDate === '') {
                    return 1;
                }
                if (b.irrigationForecastDate === '') {
                    return -1;
                }
                return DateTime.fromISO(a.irrigationForecastDate).toMillis() - DateTime.fromISO(b.irrigationForecastDate).toMillis();
            });

            tmp = IrrigationForecastArray;
            break;
        case sortConst.Irrigation_Forecast_Date_DESC:
            IrrigationForecastArray.sort((b, a) => {
                if (a.irrigationForecastDate === '') {
                    return -1;
                }
                if (b.irrigationForecastDate === '') {
                    return 1;
                }
                return DateTime.fromISO(a.irrigationForecastDate).toMillis() -  DateTime.fromISO(b.irrigationForecastDate).toMillis();
            });
            tmp = IrrigationForecastArray;
            break;
        case sortConst.Field_Name_ASC:
            IrrigationForecastArray.sort((a, b) => {
                return (a.fieldName || '').localeCompare((b.fieldName || ''));
            });
            tmp = IrrigationForecastArray;
            break;
        case sortConst.Field_Name_DESC:
            IrrigationForecastArray.sort((b, a) => {

                return (a.fieldName || '').localeCompare((b.fieldName || ''));
            });
            tmp = IrrigationForecastArray;
            break;
        case sortConst.Crop_ASC:
            IrrigationForecastArray.sort((a, b) => {

                return (a.cropName || '').localeCompare((b.cropName || ''));
            });
            tmp = IrrigationForecastArray;
            break;
        case sortConst.Crop_DESC:
            IrrigationForecastArray.sort((b, a) => {
                return (a.cropName || '').localeCompare((b.cropName || ''));
            });
            tmp = IrrigationForecastArray;
            break;
        case sortConst.Irrigation_Override_Date_ASC:
            IrrigationForecastArray.sort((a, b) => {

                return a.irrigationOverrideDate - b.irrigationOverrideDate;
            });
            tmp = IrrigationForecastArray;
            break;
        case sortConst.Irrigation_Override_Date_DESC:
            IrrigationForecastArray.sort((b, a) => {
                return a.irrigationOverrideDate - b.irrigationOverrideDate;
            });
            tmp = IrrigationForecastArray;
            break;
    }
    return tmp;
}