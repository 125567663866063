import { DateTime } from 'luxon';
import * as React from 'react';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { EChartsSeriesType } from './SoilTotalContext';
import { valueRounder } from '../../utils/numericConversions';
import { useAppSelector } from '../../stores/hooks';
import { aestToLocationLocalTimeConverter } from '../../utils/DateConvertor';


export interface CanopyStressGraphSeriesType {
  temp: EChartsSeriesType,
  canopy_temp_forecast: EChartsSeriesType,
  threshold: any,
  air_temp: EChartsSeriesType,
  air_temp_forecast: EChartsSeriesType,
  solar_rad_forecast: EChartsSeriesType,
  ambient_temp: EChartsSeriesType,
  rel_humid: EChartsSeriesType,
  solar_rad: EChartsSeriesType,
  rel_humid_forecast: EChartsSeriesType,
  acc_stress_time_under__one_hundred: EChartsSeriesType,
  acc_stress_time_over__one_hundred: EChartsSeriesType,
  stress_time_forecast: EChartsSeriesType,
  stressHoursAbove100: number,
  sumStressAbove5hrs: number,
  stressValue: any[] | null,
  stress_time: EChartsSeriesType,
  stress_time_forecast_bar: EChartsSeriesType,
};

export interface CanopyStressContextDataType {
  loading: boolean,
  legend: string[],
  CanopyStressGraphSeries: CanopyStressGraphSeriesType,
  irrigationThreshold: number,
  setIrrigationThreshold: React.Dispatch<React.SetStateAction<number>>
}


export interface ICanopyStressProviderProps {
  children: React.ReactNode,
}

const CanopyStressContext = React.createContext({} as CanopyStressContextDataType);

const DEFAULT_DRIP_THRESHOLD = 5;

export default function CanopyStressContextProvider(props: ICanopyStressProviderProps) {

  const { dateRange, canopyStress, canopyStressForecast, canopyStressLoading, soilProbesData, LocationsEdit } = useFieldDetailContext();
  const [CanopyStressGraphSeries, setCanopyStressGraphSeries] = React.useState({} as CanopyStressGraphSeriesType);
  const { numericSystem }: { numericSystem: string } = useAppSelector((state) => state.user);
  const legend = new Set(['Canopy Temp', 'Air Temperature', 'Ambient Temperature', 'Humidity', 'Canopy Temp Forecast', 'Air Temperature Forecast', 'Humidity Forecast', 'Temp Threshold', 'Canopy Irrigation Threshold'])
  const [irrigationThreshold, setIrrigationThreshold] = React.useState<number>(LocationsEdit.data?.data.value[0].CanopyIrrigationThreshold ? Number(LocationsEdit.data?.data.value[0].CanopyIrrigationThreshold) : 100);

  //update canopy stress serie
  React.useEffect(() => {
    if (canopyStress && canopyStress.data) {
      const series = convertCanopyStreesDataSerie();
      const stressHours = convertStressHoursAbove100();
      const sumStressAbove5hrs = convertStressHoursAbove5Hrs();

      setCanopyStressGraphSeries((prevState) => {
        return {
          ...prevState,
          threshold: series.threshold,
          temp: series.temp,
          air_temp: series.air_temp,
          rel_humid: series.rel_humid,
          solar_rad: series.solar_rad,
          ambient_temp: series.ambient_temp,
          acc_stress_time_under__one_hundred: series.acc_stress_time_under_one_hundred,
          acc_stress_time_over__one_hundred: series.acc_stress_time_over_one_hundred,
          stressHoursAbove100: stressHours,
          sumStressAbove5hrs: sumStressAbove5hrs,
          stress_time: series.stress_time
        }
      })
    }
  }, [canopyStress]);

  //update canopy stress forecast serie
  React.useEffect(() => {
    if (canopyStressForecast.data) {
      if (canopyStress && canopyStress.data) {
        if (Array.isArray(canopyStress.data?.canopy_stresses.data)) {
          const lastPoint = canopyStress.data?.canopy_stresses.data[canopyStress.data?.canopy_stresses.data.length - 1];
          const { stress_time_forecast, stress_time_forecast_bar, stressValue } = convertStressTimeForecastDataSerie(lastPoint);
          const series = convertCanopyStressForecastDataSerie(lastPoint);
          setCanopyStressGraphSeries((prevState) => {
            return {
              ...prevState,
              air_temp_forecast: series.air_temp_forecast,
              canopy_temp_forecast: series.canopy_temp_forecast,
              solar_rad_forecast: series.solar_rad_forecast,
              rel_humid_forecast: series.rel_humid_forecast,
              stress_time_forecast,
              stress_time_forecast_bar,
              stressValue,
            }
          })
        }
      }
      else {
        const series = convertCanopyStressForecastDataSerie()
        setCanopyStressGraphSeries((prevState) => {
          return {
            ...prevState,
            air_temp_forecast: series.air_temp_forecast,
            canopy_temp_forecast: series.canopy_temp_forecast,
            solar_rad_forecast: series.solar_rad_forecast,
            rel_humid_forecast: series.rel_humid_forecast
          }
        });
      }
    }
  }, [canopyStressForecast.data, canopyStress, soilProbesData]);

  //process data to SoilToal serie
  const convertCanopyStreesDataSerie = () => {
    const series = {
      threshold: {
        name: 'Threshold',
        data: null
      },
      temp: {
        type: 'line' as any,
        name: 'Canopy Temp',
        data: new Array<any>()
      },
      air_temp: {
        type: 'line' as any,
        name: 'Air Temperature',
        data: new Array<any>()
      },
      ambient_temp: {
        type: 'line' as any,
        name: 'Ambient Temperature',
        data: new Array<any>()
      },
      rel_humid: {
        type: 'line' as any,
        name: 'Humidity',
        data: new Array<any>()
      },
      solar_rad: {
        type: 'line' as any,
        name: 'solar rad',
        data: new Array<any>()
      },
      acc_stress_time_under_one_hundred: {
        type: 'line' as any,
        name: 'Crop Stress Under 100%',
        data: new Array<any>()
      },
      acc_stress_time_over_one_hundred: {
        type: 'line' as any,
        name: 'Crop Stress Above 100%',
        data: new Array<any>()
      },
      stress_time: {
        type: 'bar' as any,
        name: 'Stress Time',
        data: new Array<any>()
      },
    };

    series.threshold = canopyStress.data?.canopy_stresses?.threshold ?? null;
    const aggregatedDataStressTime: { [key: string]: [string, number] } = {};
    const DripThreshold = soilProbesData.data?.data?.value?.length ? soilProbesData.data?.data?.value[0]?.DripThreshold : DEFAULT_DRIP_THRESHOLD;

    canopyStress.data?.canopy_stresses.data.forEach((element: any) => {
      if (element.date_time) {
        //the date_time contains a Z where it is not actuallty in zulu time, removed here to avoid timezone change
        const timePoint = element.date_time.replace('Z', '');
        if (element.temp) {
          const tmp = [timePoint, element.temp];
          series.temp.data.push(tmp);
        }
        if (element.air_temp) {
          const tmp = [timePoint, element.air_temp];
          series.air_temp.data.push(tmp);
        }
        if (element.ambient_temp) {
          const tmp = [timePoint, element.ambient_temp];
          series.ambient_temp.data.push(tmp);
        }
        if (element.rel_humid) {
          const tmp = [timePoint, element.rel_humid];
          series.rel_humid.data.push(tmp);
        }
        if (element.acc_stress_time !== undefined) {
          const tmp = [timePoint, element.acc_stress_time ? (element.acc_stress_time !== 0 ? element.acc_stress_time : null) : null];

          if (element.acc_stress_time > 100) {
            series.acc_stress_time_over_one_hundred.data.push(tmp);
            series.acc_stress_time_under_one_hundred.data.push([timePoint, null]);
          }
          else {
            series.acc_stress_time_under_one_hundred.data.push(tmp);
            series.acc_stress_time_over_one_hundred.data.push([timePoint, null]);
          }
        }
        // used for aggregation for drip
        const date = new Date(timePoint);
        const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        if (element.stress_time !== undefined) {

          if (!aggregatedDataStressTime[day]) {
            aggregatedDataStressTime[day] = [`${day}T00:00:00.000`, 0];
          }

          // aggregate by sum v1 logic
          aggregatedDataStressTime[day][1] += (element.stress_time / DripThreshold) * 100;
        }
        if (element.solar_rad) {
          let solarRad = element.solar_rad;
          if (typeof element.solar_rad === 'number') {
            solarRad = numericSystem === 'M' ? element.solar_rad : valueRounder(element.solar_rad / 11.63, 2);
          }
          const tmp = [timePoint, solarRad]
          series.solar_rad.data.push(tmp);
        }

      }
    });
    series.stress_time.data = Object.values(aggregatedDataStressTime);

    return series;
  };

  const convertCanopyStressForecastDataSerie = (lastPoint?: any | null) => {
    const series = {

      air_temp_forecast: {
        type: 'line' as any,
        name: 'Air Temperature Forecast',
        data: new Array<any>()
      },
      canopy_temp_forecast: {
        type: 'line' as any,
        name: 'Canopy Temp Forecast',
        data: new Array<any>()
      },
      solar_rad_forecast: {
        type: 'line' as any,
        name: 'Solar Radiation Forecast',
        data: new Array<any>()
      },
      rel_humid_forecast: {
        type: 'line' as any,
        name: 'Humidity Forecast',
        data: new Array<any>()
      }
    };
    canopyStressForecast.data?.weather_forecast.data.forEach((element: any) => {
      if (element.date_time
        && DateTime.fromISO(element.date_time).toISODate() <= (dateRange[1] as DateTime).toISODate()
        && lastPoint?.date_time ? element.date_time >= lastPoint?.date_time : true) {
        if (element.air_temp_forecast) {
          const tmp = [element.date_time, element.air_temp_forecast];
          series.air_temp_forecast.data.push(tmp);
        }
        if (element.canopy_temp_forecast) {
          const tmp = [element.date_time, element.canopy_temp_forecast];
          series.canopy_temp_forecast.data.push(tmp);
        }
        if (element.solar_rad_forecast) {
          let solarRad = element.solar_rad_forecast;
          if (typeof element.solar_rad_forecast === 'number') {
            solarRad = numericSystem === 'M' ? element.solar_rad_forecast : valueRounder(element.solar_rad_forecast / 11.63, 2);
          }
          const tmp = [element.date_time, solarRad];
          series.solar_rad_forecast.data.push(tmp);
        }
        if (element.rel_humid_forecast) {
          const tmp = [element.date_time, element.rel_humid_forecast];
          series.rel_humid_forecast.data.push(tmp);
        }
      }
    });
    return series;
  };

  const convertStressTimeForecastDataSerie = (lastPoint: any | null) => {
    const stress_time_forecast: {
      type: any,
      name: string,
      data: any[],
    } = {
      type: 'line' as any,
      name: 'Crop Stress Forecast',
      data: new Array<any>(),
    };
    const stress_time_forecast_bar: {
      type: any,
      name: string,
      data: any[],
    } = {
      type: 'bar' as any,
      name: 'Stress Time Forecast',
      data: new Array<any>()
    };
    let stressValue: any[] | null = null;

    const aggregatedDataStressTimeForecastBar: { [key: string]: [string, number] } = {};
    const DripThreshold = soilProbesData.data?.data?.value?.length ? soilProbesData.data?.data?.value[0]?.DripThreshold : DEFAULT_DRIP_THRESHOLD;

    //if the irrigation forecast is set to canopy even if forecast is missing we still use irrigationdueforecast
    if (canopyStressForecast.data?.weather_forecast.data.length === 0
      && LocationsEdit.data?.data.value[0].ExternalLocationID === "Forecast=Canopy"
    ) {
      const forecastValue = lastPoint?.acc_stress_time !== null ? (lastPoint.acc_stress_time.toFixed(2)) : null;
      if (forecastValue < irrigationThreshold) {
        const irrigationForecastCanopy = aestToLocationLocalTimeConverter(soilProbesData.data?.data?.value[0]?.IrrigationDueForecast, soilProbesData.data?.data?.value[0]?.GMTDifference)
        const tmp = [irrigationForecastCanopy, irrigationThreshold];
        stress_time_forecast.data.push(tmp);
        stressValue = tmp;
      }
    }

    //get the irrigation forecast from the backend then map the datetime to the correct date point
    const irrigationForecastCanopy = aestToLocationLocalTimeConverter(soilProbesData.data?.data?.value[0]?.IrrigationDueForecast, soilProbesData.data?.data?.value[0]?.GMTDifference)
    const irrigationForecastCanopyDataPoint = [irrigationForecastCanopy, null];

    canopyStressForecast.data?.weather_forecast.data.forEach((element: any, index: number) => {
      if (
        element.date_time
        && DateTime.fromISO(element.date_time).valueOf() <= (dateRange[1] as DateTime).valueOf()
        && DateTime.fromISO(element.date_time + ".000Z").valueOf() > DateTime.fromISO(lastPoint?.date_time).valueOf()
      ) {
        if (element.acc_stress_time_above_threshold_forecast !== undefined && element.acc_stress_time_above_threshold_forecast !== null) {

          //getting the last point canopy stress and add the stress time over threshold forecast is the forecasted canopy stress
          const forecastValue = lastPoint?.acc_stress_time !== null ? ((element.acc_stress_time_above_threshold_forecast + lastPoint.acc_stress_time).toFixed(2)) : null;
          const tmp = [element.date_time, forecastValue];

          stress_time_forecast.data.push(tmp);

          //map the irrigation forecast data
          if (irrigationForecastCanopyDataPoint[1] === null && element.date_time > irrigationForecastCanopy) {
            irrigationForecastCanopyDataPoint[1] = forecastValue;
            stress_time_forecast.data.push(irrigationForecastCanopyDataPoint);
          }

          // used for aggregation for drip
          const date = new Date(element.date_time);
          const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;


          if (element.stress_time_forecast !== undefined) {

            if (!aggregatedDataStressTimeForecastBar[day]) {
              aggregatedDataStressTimeForecastBar[day] = [`${day}T00:00:00.000`, 0];
            }

            // aggregate by sum v1 logic
            aggregatedDataStressTimeForecastBar[day][1] += (element.stress_time_forecast / DripThreshold) * 100;
          }
        }
      }
    });

    //If the forecast is canopy we display the forecast
    if (LocationsEdit.data?.data.value[0].ExternalLocationID === "Forecast=Canopy") {

      //The logic is weird but it works, add a point if the forecast date exceeded the maximum forecast datetime
      if (irrigationForecastCanopyDataPoint[1] === null) {
        irrigationForecastCanopyDataPoint[1] = irrigationThreshold;
        stress_time_forecast.data.push(irrigationForecastCanopyDataPoint);
      }
      
      stressValue = irrigationForecastCanopyDataPoint
    }

    stress_time_forecast_bar.data = Object.values(aggregatedDataStressTimeForecastBar);
    return { stress_time_forecast, stress_time_forecast_bar, stressValue }
  };


  const convertStressHoursAbove100 = () => {
    const periodArray = canopyStress.data?.canopy_stresses.periods;
    let stressHours: number = 0;
    if (Array.isArray(periodArray)) {
      periodArray.forEach((element) => {
        if (element.type === "stressed") {
          stressHours += element.end_deficit_hours - element.start_deficit_hours;
        }
      })
    }
    return stressHours;
  }

  const convertStressHoursAbove5Hrs = () => {
    // V1 logic copied
    let sum_above = 0;
    let stress_time_per_day = 0;
    let last_date_time_in_minutes = 0;

    canopyStress.data?.canopy_stresses.data.forEach((item: any) => {
      // removing local Time zone offset
      let current_date_time = new Date(item.date_time);
      let converted_date_time = new Date(current_date_time.getTime() + current_date_time.getTimezoneOffset() * 60 * 1000);

      const current_date_time_in_minutes = converted_date_time.getHours() * 60 + converted_date_time.getMinutes();

      if (current_date_time_in_minutes >= last_date_time_in_minutes) {
        stress_time_per_day += item.stress_time;
      }
      else {
        if (stress_time_per_day > 5) sum_above += stress_time_per_day - 5;
        stress_time_per_day = item.stress_time;
      }
      last_date_time_in_minutes = current_date_time_in_minutes;

    });

    if (stress_time_per_day > 5) sum_above += stress_time_per_day - 5;

    return sum_above
  }


  return (
    <>
      <CanopyStressContext.Provider value={{ legend: Array.from(legend), CanopyStressGraphSeries: CanopyStressGraphSeries, loading: canopyStressForecast.loading || canopyStressLoading, irrigationThreshold, setIrrigationThreshold }}>
        {props.children}
      </CanopyStressContext.Provider>
    </>
  );
}

export { CanopyStressContext, CanopyStressContextProvider };