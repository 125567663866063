import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from './style.module.css';
import { Trans, useTranslation } from "react-i18next";

// all the icons
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/Navbar/ic_home.svg';
import { ReactComponent as HelpIcon } from '../../assets/images/Navbar/ic_help.svg';
import { ReactComponent as TrendingUpIcon } from '../../assets/images/Navbar/trending_up.svg';
import { ReactComponent as GTIcon } from '../../assets/images/Navbar/greater_than.svg';
import { useDispatch, useSelector } from "react-redux";
import { setNavbarOpen, toggleNavbarOpen } from "../../stores/Slices/navbarSlice";
import { IconButton } from "@mui/material";
import { setDebug } from "../../stores/Slices/userSlice";
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

interface INavbarProps { }

function Navbar(props: INavbarProps) {

    const { t } = useTranslation('Navbar');
    const { navbarOpen }: { navbarOpen: boolean } = useSelector((state: any) => state.navbar);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const pageName = pathname.split('/')[2];
    useEffect(() => { }, [navbarOpen])

    const debug: boolean = useSelector((state: any) => state.user).debug;
    const roles: string[] = useSelector((state: any) => state.user).role;
    const isNotAdmin = roles.findIndex((role: string) => role == "Goanna Administrators") == -1
    const v1Url = sessionStorage.getItem('V1url');

    // check so any non admins can't see the debug menu
    if (isNotAdmin && debug) {
        dispatch(setDebug(false));
    }

    useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 1024) {
                dispatch(setNavbarOpen(false));
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const linkOpacity = navbarOpen ? 1 : 0;
    const linkWidth = navbarOpen ? '203px' : 'min-content';

    return (
        <div className={styles.page}>
            <div className={styles.sidenav} style={{ width: navbarOpen ? '250px' : '79px' }}>
                <div className={styles.sidenavTop}>
                    <Link
                        to="dashboard"
                        className={styles.closebtn}
                        onClick={(e) => { e.preventDefault(); dispatch(toggleNavbarOpen()); }}>
                        <div className={styles.logoName}>
                            <Logo width={'24px'} />
                            <span
                                className={[styles.Logo, styles.LinkText].join(' ')}
                                style={{ opacity: linkOpacity }}
                            >
                                <Trans i18nKey="Navbar:Navbar-logoText" components={[
                                    <span key="goanna" />,
                                    <span key="ag" className={styles.LogoAg} />
                                ]}
                                />
                            </span>
                        </div>
                        <div className={styles.GtIcon} style={{ marginLeft: navbarOpen ? '55px' : '-97px', transform: navbarOpen ? 'rotateY(180deg)' : '' }}>
                            <IconButton sx={{ width: "40px", height: '40px', '&:hover': { background: "rgba(9, 88, 255, 0.04)" } }}>
                                <GTIcon />
                            </IconButton>
                        </div>
                    </Link>

                    <Link to="dashboard" className={pageName === 'dashboard' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <HomeIcon />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-home')}</span>
                    </Link>

                    <Link to="goField" className={pageName === 'goField' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <TrendingUpIcon />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-forecast')}</span>
                    </Link>
                    {/* 
                    <Link to="news" className={pageName === 'news' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <BellIcon />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-news')}</span>
                    </Link>

                    <Link to="record" className={pageName === 'record' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <PenIcon />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-record')}</span>
                    </Link>

                    <Link to="history" className={pageName === 'history' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <CalenderIcon />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-history')}</span>
                    </Link> */}

                    <Link to="help" className={pageName === 'help' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <HelpIcon />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-help')}</span>
                    </Link>

                    {/* <Link to="invoices" className={pageName === 'invoices' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <div style={{ width: '24px', display: 'inline', fontSize: '24px' }}><DollarSignIcon /></div>
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-invoices')}</span>
                    </Link> */}

                    <Link to="settings" className={pageName === 'settings' ? styles.selected : ''} style={{ width: linkWidth }}>
                        <SettingsIcon sx={{ color: "#7E84A3" }} />
                        <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{t('Navbar-settings')}</span>
                    </Link>
                    {
                        v1Url ?
                            <a href={v1Url} style={{ width: linkWidth}}>
                                <ExitToAppIcon sx={{ color: "#7E84A3" }} />
                                <span className={styles.LinkText} style={{ opacity: linkOpacity }}>{v1Url}</span>
                            </a>
                            :
                            <></>
                    }
                    <a  href="https://goapp.dev.goannaag.net/">
                        <SettingsIcon />
                        <span className={styles.LinkText}>goapp</span>
                    </a>
                </div>
                <div style={{
                    marginBottom: '100px',
                    marginTop: '5px',
                }}>
                    {/* <div className={styles.userprofile} onClick={() => navigate('/pages/settings')}>
                        <img height={28} src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png"></img>
                        <span className={styles.LinkText} style={{ opacity: linkOpacity, marginTop: '5px',}}>User Name</span>
                    </div> */}
                </div>
            </div>


            {/* GAP between topbar and navbar 252px - 250px = 2px AND 81px - 79px = 2px  */}
            {/* <div className={styles.topbar} style={{ marginLeft: navbarOpen ? '249px' : '78px', borderLeft: "3px solid #F4F7FC" }} >
                <Topbar />
            </div> */}

            {/* <div id={styles.main} style={{ marginLeft: navbarOpen ? '250px' : '79px' }}>
                <div className={styles.children}>
                    {props.children}
                </div>
            </div> */}
        </div>
    );
}

export default Navbar;