import * as React from 'react';
import styles from './style.module.css';
import { Backdrop } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/Navbar/ic_home.svg';
import { ReactComponent as HelpIcon } from '../../assets/images/Navbar/ic_help.svg';
import { ReactComponent as SettingIcon } from '../../assets/images/Navbar/ic_setting.svg';
import { ReactComponent as TrendingUpIcon } from '../../assets/images/Navbar/trending_up.svg';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useTopBarTitle from '../../hooks/TopbarHooks/useTopBarTitle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export interface ITopbarProps {
    // children: React.ReactNode
}

export default function Topbar(props: ITopbarProps) {

    const [menuOpen, setMenuOpen] = React.useState(false);

    const toggleMenu = () => {
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const TITLE_MAPPING: { [key: string]: string } = {
        'dashboard': 'dashboard',
        'FieldTable': 'Field Table',
        'FieldList': 'Field List',
        'help': 'Help',
        'settings': 'Settings'
    }
    const { pathname } = useLocation();
    const pageName = pathname.split('/')[2];
    const { t } = useTranslation('Navbar');
    const { title } = useTopBarTitle();
    const v1Url = sessionStorage.getItem('V1url');
    return (
        <div className={styles.App}>
            <header className={styles.topbar}>
                <div className={styles.hamburger} onClick={toggleMenu}>
                    <div className={styles.line}></div>
                    <div className={styles.line} id={styles['center']}></div>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.title}>
                    {title}
                </div>
            </header>
            {menuOpen && (
                <div className={`${styles.menu} ${styles.open}`} >
                    <Link
                        to="dashboard"
                        className={styles.closebtn}
                        onClick={(e) => { closeMenu(); e.preventDefault(); }}>
                        <div className={styles.logoName}>
                            <Logo width={'24px'} />
                            <span
                                className={[styles.Logo, styles.LinkText].join(' ')}
                            >
                                Goanna
                                <span className={styles.LogoAg}> Ag</span>
                            </span>
                        </div>
                    </Link>
                    <Link to="dashboard" className={pageName === 'dashboard' ? styles.selected : ''} onClick={closeMenu}>
                        <HomeIcon />
                        <span className={styles.LinkText}>{t('Navbar-home')}</span>
                    </Link>

                    <Link to="goField" className={pageName === 'goField' ? styles.selected : ''} onClick={closeMenu}>
                        <TrendingUpIcon />
                        <span className={styles.LinkText}>{t('Navbar-forecast')}</span>
                    </Link>
                    <Link to="help" className={pageName === 'help' ? styles.selected : ''} onClick={closeMenu}>
                        <HelpIcon />
                        <span className={styles.LinkText}>{t('Navbar-help')}</span>
                    </Link>
                    <Link to="settings" className={pageName === 'settings' ? styles.selected : ''} onClick={closeMenu}>
                        <SettingIcon />
                        <span className={styles.LinkText}>{t('Navbar-settings')}</span>
                    </Link>
                    {
                        v1Url ?
                        <a href={v1Url} onClick={closeMenu}>
                        <ExitToAppIcon />
                        <span className={styles.LinkText}>{v1Url}</span>
                    </a>
                    :
                    <></>
                    }
                    <a  href="https://goapp.dev.goannaag.net/">
                        <SettingIcon />
                        <span className={styles.LinkText}>goapp</span>
                    </a>
                </div>
            )}
            <Backdrop
                sx={{ color: '#fff', zIndex: 98 }}
                open={menuOpen}
                onClick={closeMenu}
            />
            {/* <div className={styles.content} onClick={closeMenu}>
                {props.children}
            </div> */}
        </div>
    );
}