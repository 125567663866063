import { Dialog, DialogTitle, DialogContent, Typography, CircularProgress, TextField, Box, DialogActions, Button, Tooltip } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useGetMfaUri } from "../../../hooks/ApiHooks/useMFA";
import { useEffect, useState } from "react";
import { useSetMfa } from "../../../hooks/ApiHooks/useSetMfa";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../../stores/hooks";

interface ISetupMFADialogBox {
    open: boolean,
    onClose: () => void
}

function extractKeyFromOtpUrl(url: string) {
    const urlParts = url.split('?');
    const queryParams = urlParts[1].split('&');
    const secretParam = queryParams.find(param => param.startsWith('secret='));
    if (!secretParam) throw Error("Invalid Param")
    const key = secretParam.split('=')[1];
    return key;
}

export default function SetupMFADialogBox(props: ISetupMFADialogBox) {
    const { enqueueSnackbar } = useSnackbar();
    const userID = useAppSelector(state => state.user.userID);

    const [userMfaCode, setUserMfaCode] = useState<string | null>("");
    const { getMfaUri, loading: loading1, error, mfaUri, abort } = useGetMfaUri(userID);
    const { setMfa, loading: loading2, error: error2 } = useSetMfa(userID);

    useEffect(() => {
        if (props.open)
            getMfaUri()

        return () => { abort() }
    }, [props.open])

    useEffect(() => {
        if (error || error2){
            enqueueSnackbar(error?.message || error2?.message, { variant: "error", persist: false })
        }

    }, [enqueueSnackbar, error, error2])

    const onSubmit = () => {
        if (userMfaCode && mfaUri)
            setMfa(extractKeyFromOtpUrl(mfaUri), userMfaCode)
        else
            enqueueSnackbar("Multi Factor Authentication Code must be 6 digits and numeric", { variant: "error", persist: false })
    }

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>SetUpMFA</DialogTitle>
        <DialogContent>
            <Typography>
                To setup MFA please scan this QR code:
            </Typography>
            <br />
            <Box display="flex" justifyContent="center" alignItems="center" height={250}>
                {loading1
                    ? <CircularProgress />
                    : (error
                        ? <Typography>Error: {error.message}</Typography>
                        : mfaUri && <QRCodeSVG value={mfaUri} size={250} />)}
            </Box>
            <br />
            <hr />
            <Typography>
                Once you have set up in your authenticator app, please provide the code provided by the app to finalize the setup.
            </Typography>
            <Box display="flex" alignItems="center" columnGap={1}>
                <Typography>Multi Factor Authentication Code: </Typography>
                <Tooltip title={"Six digits"} placement="bottom-start">
                    <TextField
                        id="outlined-required"
                        label="Code"
                        variant="outlined"
                        size="small"
                        type="number"
                        value={userMfaCode}
                        autoComplete="one-time-code"
                        onChange={(event) => { setUserMfaCode(event.target.value) }}
                    />
                </Tooltip>
            </Box>
        </DialogContent>
        <DialogActions>
            {loading2
                ? <CircularProgress />
                : <Button onClick={onSubmit}>Add</Button>}
        </DialogActions>
    </Dialog>;
}