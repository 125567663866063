import { gql, useLazyQuery } from '@apollo/client';
import * as React from 'react';

import { useAppDispatch } from '../../stores/hooks';
import { setUser, initialState, userState, shortCutPagesValueType, setNumericSystem } from '../../stores/Slices/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';

import { Credentials, LoginData, fetchToken } from '../../api/services/login';
import { secondaryClient } from '../../api/apollo';
import i18n from '../../locales/i18n';
import { http } from '../../api/http';
import { Dispatch, SetStateAction } from 'react';
import useTimeZones from '../../hooks/ApiHooks/useTimezones';
import LogRocket from 'logrocket';
import { updatePreferences } from '../../utils/dataTransformation';
import { UpdateTablePrefrenceInDB } from '../../pages/Dashboard/FieldTable/NewFieldTable';
import { useCacheBuster } from 'react-cache-buster';
import getVersionFromLocalStorage from '../../utils/Versioning';
import { usePermission } from 'react-permission-role';

export interface IAppProps {
  children: React.ReactNode
}

export interface AuthContextValue {
  token: LoginData,
  setToken: Dispatch<SetStateAction<LoginData>>
  isAuthenticated: boolean,
  isV2User: boolean | null,
  onLogin: (loginCredentials: Credentials) => {},
  onLogout: () => void
  onLoginData: () => any
}

export const EMPTY_TOKEN: LoginData = {
  token_type: '',
  access_token: '',
  expires_in: '',
  access_roles: []
};

const initialValue: AuthContextValue = {
  token: EMPTY_TOKEN,
  setToken: () => { },
  isAuthenticated: false,
  isV2User: null,
  onLogin: () => { return {} },
  onLogout: () => { },
  onLoginData: () => { }
}

const url = process.env.REACT_APP_AXIOS_URL;

export const AuthContext = React.createContext(initialValue);

export function AuthProvider({ children }: IAppProps) {

  const [token, setToken] = React.useState<LoginData>(EMPTY_TOKEN);
  const [isV2User, setIsV2User] = React.useState<boolean | null>(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const { checkCacheStatus } = useCacheBuster();
  const { setUser: setUserPerms } = usePermission();

  const GET_USER = gql`query ($accesstoken: String!) {
    SearchUserSetting (where: {authToken: $accesstoken})
    {
      userID
      preferredFields
      tablePreference
      graphPreference
      dashBoardPreference
      shortCutPages
      numericSystem
      dateRange
      timezone
      sortModel
      filterModel
      language
      }
    }
   `;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const TimeZonesQuery = useTimeZones();

  async function getV1User() {
    // api/userProfileDetailed/userProfileDetailed

    const data: any = await http.get("api/userProfileDetailed/userProfileDetailed")
    // Only use V1 Numeric system for this gql is not used
    return data.data.MetricImperial == 0 ? "M" : "I";
  }

  function afterGetUser(data: { SearchUserSetting: any[]; }, loginCredentials: Credentials, numericSystem: string) {
    const user = (data && (data.SearchUserSetting.length > 0))
      ? data.SearchUserSetting[0]
      : undefined;
    let tmp: userState = {
      ...initialState
    };

    if (user) {
      try {
        if (user.userID) {
          tmp.userID = JSON.parse(user.userID);
          LogRocket.identify(String(tmp.userID), {
            name: loginCredentials.username
          });
        }
        if (user.preferredFields) tmp.preferredFields = JSON.parse(user.preferredFields) as number[];

        if (user.tablePreference) {
          const tempTablePreference = JSON.parse(user.tablePreference) as userState["tablePreference"];

          if (!Array.isArray(tempTablePreference)) {
            tmp.tablePreference = updatePreferences(tempTablePreference, "T");
          }
          else {
            UpdateTablePrefrenceInDB(initialState.tablePreference)
          }

          // ELSE: if the older version of table prefrence is there with the type number[]
          // we use initial values
        }

        if (user.graphPreference) {
          const tempVariable = JSON.parse(user.graphPreference) as userState["graphPreference"];
          tmp.graphPreference = updatePreferences(tempVariable, "G");
        };
        if (user.dashBoardPreference) tmp.dashBoardPreference = JSON.parse(user.dashBoardPreference) as number[];
        if (user.shortCutPages) tmp.shortCutPages = JSON.parse(user.shortCutPages) as shortCutPagesValueType[];
        if (user.dateRange) tmp.dateRange = user.dateRange;
        if (user.sortModel) tmp.sortModel = JSON.parse(user.sortModel);
        if (user.filterModel) tmp.filterModel = JSON.parse(user.filterModel);
        const TimeZoneData: Array<any> = TimeZonesQuery.data?.data?.value.map((a: any): userState["timezone"] => ({
          name: a.TimeZoneName,
          code: a.TimeZoneCode,
          AESTDifference: a.GMTDifference,
          ID: a.TimeZoneID
        }))

        if (user.timezone) tmp.timezone = TimeZoneData.find(a => a.ID === JSON.parse(user.timezone ?? 28)); // Defaults to AEST for new users; 15 = AEST
        if (user.language) {
          tmp.language = user.language;
          i18n.changeLanguage(tmp.language);
        }

        tmp.numericSystem = numericSystem;
      }
      catch (err) {
        console.log(err);
      }
    }
    else {
      const CREATE_USER_PROFILE = gql`mutation {
        InsertUserSetting(input: {shortCutPages: "[]", timezone: "28", authToken: ${localStorage.getItem("access_token")}}) {
          userID
        }
      }`

      secondaryClient.mutate({ mutation: CREATE_USER_PROFILE }).catch(e => { })
    }
    tmp.role = getLoginData().access_roles;
    dispatch(setUser(tmp));
    setUserPerms({
      roles: tmp.role,
      id: tmp.userID
    })
  }

  const handleLogin = async (loginCredentials: Credentials) => {
    const fetchedToken: LoginData = await fetchToken(loginCredentials)
    const tmp: LoginData = fetchedToken;
    let V2User = true;

    if (tmp.access_token !== '' && V2User) {
      setLoginData(tmp, true);
      const numericSystem = await getV1User();
      setIsV2User(true);

      secondaryClient.query({ query: GET_USER, variables: { accesstoken: tmp.access_token.replaceAll("\"", "") } }).then((data) => {
        TimeZonesQuery.refetch().then(
          () => {
            afterGetUser(data.data, loginCredentials, numericSystem);
            getVersionFromLocalStorage();
            checkCacheStatus();
            navigate('pages/dashboard');
          }
        );
      }).catch((e) => {
        console.error("something went wrong with graphql, Error: ", e);
      });
    }
    else {
      setLoginData(tmp, false);
    }
  }

  const handleLogout = () => {
    setToken(EMPTY_TOKEN);
    setIsAuthenticated(false);
    localStorage.removeItem('loginData');
    localStorage.removeItem('access_token');
    let version = localStorage.getItem('version');
    localStorage.removeItem('persist:root');
    localStorage.clear();
    version && localStorage.setItem('version', version);
    navigate('/')
  };

  const setLoginData = (token: LoginData | null, isAuthenticated: boolean) => {
    if (token) {
      setToken(token);
      if (isAuthenticated) {
        localStorage.setItem('loginData', JSON.stringify(token));
        localStorage.setItem('access_token', JSON.stringify(token.access_token));
      }
    }
    setIsAuthenticated(isAuthenticated);
  };

  const getLoginData = () => {
    const loginData: string = localStorage.getItem('loginData') ?? '';
    return JSON.parse(loginData);
  };


  const value: AuthContextValue = {
    token,
    setToken,
    isV2User,
    isAuthenticated,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onLoginData: getLoginData
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
