import { AxiosError, AxiosResponse } from 'axios';
import { http } from '../../api/http';
import { MutateOptions, useMutation, useQuery } from '@tanstack/react-query';
import { usePermission } from 'react-permission-role';
import { useEffect } from 'react';

const LOCATIONS_EDIT_PATH = 'odata/LocationsEdit';
export const PUT_ROLES_REQUIRED = ["Goanna Administrators", "Account Administrators"];

type EditableFields = {
    LocationDescription?: string,
    CanopyIrrigationThreshold?: string
}

export type LocationsEditDataType = {
    AccountID: any,
    AccountName: any,
    Active: any,
    Altitude: any,
    AntennaTypeID: any,
    AntennaTypeName: any,
    AutoCreateGoSatOnProbe: any,
    CropID: any,
    CropName: any,
    DefaultUsage: any,
    DeviceCategoryID: any,
    DeviceCategoryIDLast: any,
    DeviceID: any,
    DeviceIDLast: any,
    DeviceName: any,
    DistanceFromLocation: any,
    DistanceFromLocationID: any,
    DryLand: any,
    EndDate: any,
    EndDateCanopy: any,
    ExternalID: any,
    ExternalLocationID: any,
    ExternalLocationInfo: any,
    ExternalSourceCode: any,
    FarmID: any,
    FarmName: any,
    FieldPolyCoords: any,
    Fullpoint: any,
    GoCanopyLocationDescription: any,
    GoProbeLocationDescription: any,
    GoRainLocationDescription: any,
    GoSatLocationDescription: any,
    GoWeatherLocationDescription: any,
    InstallHeight: any,
    LastFileUpdate: any,
    LastProcessErrors: any,
    LastUpdate: any,
    Latitude: any,
    LocationCoords: any,
    LocationDescription: any,
    LocationID: any,
    LocationIDCanopy: any,
    LocationIDProbe: any,
    LocationIDRain: any,
    LocationIDSatellite: any,
    LocationIDSolar: any,
    LocationIDTemp: any,
    LocationInstallDetailsID: any,
    LocationParentID: any,
    Longitude: any,
    MaceFinish: any,
    MaceStart: any,
    Notes: any,
    OwnerID: any,
    PlantingDate: any,
    RainGaugeOnly: any,
    Refillpoint: any,
    RegionID: any,
    RegionName: any,
    StartDate: any,
    StartDateCanopy: any,
    StartValue: any,
    TankContentID: any,
    TankContentName: any,
    TankDiameter: any,
    Threshold: any,
    TimeZoneID: any,
    TimeZoneName: any,
    UpdateCSVFiles: any,
    UserAccessLevel: any,
    VarietyID: any,
    VarietyName: any,
    VesselEmptyPoint: any,
    VesselFullPoint: any,
    WeatherStationID: any,
    CanopyIrrigationThreshold: number | string
}

export interface LocationEditAPIReturnType {
    data: {
        value: LocationsEditDataType[];
    }
};

export function useLocationEdit(locationID: string) {

    const query = LOCATIONS_EDIT_PATH + `(${locationID})`;
    const fetchData = async () => {
        const response: LocationEditAPIReturnType = await http.get(query);
        return response;
    };

    const Query = useQuery({
        queryKey: ['LocationEdit ', locationID],
        queryFn: fetchData,
        enabled: false
    });

    return Query;
}

export default function usePutLocationsEdit(): [typeof putLocationEditData, { loading: boolean; data?: AxiosResponse<any, any> | undefined; error?: any; }] {

    const mutation = useMutation(
        (requestBody: any) => {
            return http.put(
                `${LOCATIONS_EDIT_PATH}`,
                JSON.stringify(requestBody),
                {
                    headers: {
                        "content-type": "application/json;IEEE754Compatible=true"
                    }
                }
            );
        },
    );

    const putLocationEditData = async (
        changedData: EditableFields,
        originalData: LocationsEditDataType,
        onSuccessCallback?: (response: AxiosResponse<string, any>) => void,
        onErrorCallback?: (error: any, variables: any, context: any) => void
    ) => {
        const { LocationDescription, CanopyIrrigationThreshold } = changedData;
        const requestBody: LocationsEditDataType = {
            ...originalData,
            ...(LocationDescription !== undefined && { LocationDescription: LocationDescription.toString() }),
            ...(CanopyIrrigationThreshold !== undefined && { CanopyIrrigationThreshold }),
        };

        // Check if something was actually changed
        if (LocationDescription === undefined && CanopyIrrigationThreshold === undefined) {
            // should never reach
            throw new Error(`Nothing was changed [LocationDescription]: ${LocationDescription}`)
        }

        // @ts-expect-error: I couldnt make the error go away TS is weird
        Object.keys(requestBody).forEach((k: keyof LocationsEditDataType) => {
            // this is needed to include zeros
            if (requestBody[k] !== undefined && requestBody[k] !== null)
                requestBody[k] = '' + requestBody[k]
        })

        mutation.mutate(requestBody, {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
        });
    };

    return [
        putLocationEditData,
        {
            loading: mutation.isLoading,
            data: mutation.data,
            error: mutation.isError ? mutation.error : false
        }
    ];
}