import * as React from 'react';
import { RowContext } from '../../context/FieldDetailsContext/RowContext';

const useRowContext = () => {
    const context = React.useContext(RowContext);

    if (context === undefined) {
        throw new Error("useRowContext was used outside of its Provider");
      }
    return context;
}

export default useRowContext;