import * as React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateRangePicker, DateRangePickerToolbarProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange, DateRangeValidationError, DesktopDateRangePicker, MobileDateRangePicker, PickersShortcutsProps, StaticDateRangePicker } from "@mui/x-date-pickers-pro"
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Box, CircularProgress, IconButton, List, Popover, ListItem, SxProps, ThemeProvider, createTheme, Typography, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { NOW } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import { AEST_Zone, aestToLocationLocalTimeConverter, getDaysAfter } from '../../../../../utils/DateConvertor';
import { useAppSelector } from '../../../../../stores/hooks';
import { RootState } from '../../../../../stores/store';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import styles from './style.module.css';
import { useSnackbar } from 'notistack';


let clear = false

export default function DateRangePopup() {
  const { t } = useTranslation('FieldDetail');
  const { enqueueSnackbar } = useSnackbar();
  const { startDate, dateRange: initialDateRange, setDateRange, dateRangeReady, soilProbesData: soilProbesDataQuery, locationDevicesData: locationDevicesDataQuery } = useFieldDetailContext();
  const soilProbesData = soilProbesDataQuery.data?.data.value[0];
  const locationDevicesData = locationDevicesDataQuery.data?.data.value[0];
  const sinceStartDate: DateTime = DateTime.fromISO(aestToLocationLocalTimeConverter(locationDevicesData?.StartDate, soilProbesData?.GMTDifference));
  const maxDate = getDaysAfter(NOW, 16);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);

  const calcIntialDateRange: any = (initialDateRange: DateRange<DateTime>) => {
    let date1: any = initialDateRange[0];
    let date2: any = initialDateRange[1];
    let diff: any = date2.diff(date1, "days").days;
    if (diff === 196 && date2 === maxDate) {
      return initialDateRange;
    }
    else if (diff > 180) {
      const adjustedDateRange: DateRange<DateTime> = [initialDateRange[0], getDaysAfter(initialDateRange[0], 180)]
      return adjustedDateRange;
    }
    else {
      return initialDateRange;
    }
  };

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange<DateTime>>(calcIntialDateRange(initialDateRange));

  //important to keep
  const [tmpMobileSelectedDateRange, setTmpMobileSelectedDateRange] = useState<DateRange<DateTime>>(initialDateRange);
  const numericSystem = useAppSelector((state: RootState) => state.user.numericSystem);
  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    // Clear the timeout when anchorEl becomes falsy
    if (!anchorEl) {
      clearTimeout(calendarOpenTimeout);
    }
  }, [anchorEl]);

  useEffect(() => {
    return () => {
      clearTimeout(calendarOpenTimeout);
    };
  }, []);

  const calendarOpenTimeout = React.useMemo(() => {
    if (Boolean(anchorEl)) {
      return setTimeout(() => {
        setCalendarOpen(true);
      }, 300);
    }
  }, [anchorEl]);

  useEffect(() => {
    setSelectedDateRange(initialDateRange)
  }, [initialDateRange]);

  const [isError, setIsError] = React.useState<DateRangeValidationError | [null, null]>([null, null]);

  function handleClose() {
    setLoading(true);
    if (isError[0] === null && isError[1] === null) {
      setDateRange(selectedDateRange);
      setTmpMobileSelectedDateRange(selectedDateRange);
      setAnchorEl(null);
      setCalendarOpen(false)
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }

  function handleCloseMobile() {
    setLoading(true);
    if (isError[0] === null && isError[1] === null) {

      if (clear) {
        setSelectedDateRange(tmpMobileSelectedDateRange)
        clear = false;
      }
      else if (selectedDateRange[0] && selectedDateRange[0]) {
        setTmpMobileSelectedDateRange(selectedDateRange);
        setDateRange(selectedDateRange)
      }
      setAnchorEl(null);
      setCalendarOpen(false)
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }

  const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
      label: 'Since start',
      getValue: () => {
        // let diff = NOW.diff(sinceStartDate, 'days').days;
        // if (diff > 180) {
        //   return [sinceStartDate, getDaysAfter(sinceStartDate, 180)]
        // }
        return [sinceStartDate, maxDate];
      },
    },
    {
      label: 'Since last 7 days',
      getValue: () => {
        return [NOW.minus({ days: 7 }), maxDate];
      },
    },
    {
      label: 'Since last 30 days',
      getValue: () => {
        return [NOW.minus({ days: 30 }), maxDate];
      },
    },
    {
      label: 'Since last 60 days',
      getValue: () => {
        return [NOW.minus({ days: 60 }), maxDate];
      },
    },
    {
      label: 'Since last 180 days',
      getValue: () => {
        return [NOW.minus({ days: 180 }), maxDate];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];


  function CustomRangeShortcuts(props: PickersShortcutsProps<DateRange<DateTime>>) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return isMobile
        ?
        {
          label: item.label,
          onClick: () => {
            handleChange(newValue)
          },
          disabled: !isValid(newValue),
        }
        :
        {
          label: item.label,
          onClick: () => {
            handleChange(newValue)
          },
          disabled: !isValid(newValue),
        };
    });

    const deskTopLayout: SxProps = {
      gridRow: 2,
      gridColumn: 1,
      paddingTop: '24px'
      // marginTop: '40px',
      // marginBottom: '80px'
    };

    const mobileLayout: SxProps = {
      gridRow: 1,
      gridColumn: 2,
      // marginTop: '40px',
      // marginBottom: '80px',
      overFlowX: 'scroll'
    }


    return (
      <>
        <Divider />
        <Box
          sx={isMobile ? mobileLayout : deskTopLayout}
        >
          <List
            dense
            sx={(theme) => ({
              display: isMobile ? 'grid' : 'flex',
              '--items-to-show-in-one-row': 2,
              gridTemplateColumns: `repeat(var(--items-to-show-in-one-row), calc((100% - 10px) / var(--items-to-show-in-one-row)))`,
              flexDirection: isMobile ? 'row' : 'column',
              px: theme.spacing(1),
              '& .MuiListItem-root': {
                pt: 0,
                pl: 0,
                pr: theme.spacing(1),
              },
            })}
          >
            {resolvedItems.map((item) => {
              return (
                <ListItem key={item.label} style={{
                  paddingTop: '4px'
                }}>
                  <Chip {...item} />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Divider />
      </>

    );
  }

  function CustomPickerToolbar(props: any) {

    const dateFormat = numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    const startDate: DateTime | null = props.value[0] ? props.value[0].toFormat(dateFormat) : 'From';
    const endDate: DateTime | null = props.value[1] ? props.value[1].toFormat(dateFormat) : 'To';

    return <div style={{
      padding: '8px',
      paddingBottom: '3px'
    }}>
      <Typography className={styles.toolbartitle}>
        Date range:
      </Typography>
      <Typography className={styles.toolbarcontent} sx={{
        textAlign: 'center'
      }}>
        {
          startDate + ' - ' + endDate
        }
      </Typography>
    </div>
  }

  const errorMessageMapping: { [key: string]: string } = {
    'maxDate': `Date exceeded the maximum date ${maxDate?.isValid ? maxDate.toFormat(numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') : ''}`,
    'minDate': `Date exceeded the start date ${sinceStartDate?.isValid ? sinceStartDate.toFormat(numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') : ''}`,
    'invalidDate': 'Date invalid',
    'invalidRange': "From date cannot be later than the To date",
  };
  const startErrorMessage = React.useMemo(() => {
    if (!isError) return '';
    if (isError[0]) return errorMessageMapping[isError[0]];
    return isError[0];
  }, [isError]);

  const toErrorMessage = React.useMemo(() => {
    if (!isError) return '';
    if (isError[1] === 'invalidRange') return ''
    if (isError[1]) return errorMessageMapping[isError[1]];
  }, [isError]);

  const dateRangeValidation = (dateRange: DateRange<DateTime | null>): boolean => {

    if (dateRange[0] === null || dateRange[1] === null) return false;
    if (!dateRange[0].isValid || !dateRange[1].isValid) return false;
    //we have remove the limit on the since start date  && dateRange[0] >= sinceStartDate
    if (maxDate?.isValid
      && dateRange[0] <= maxDate
      && dateRange[1] >= sinceStartDate
      && dateRange[1] <= maxDate
      && dateRange[1] >= dateRange[0]) {
      return true;
    }
    return false;
  }

  const isMobile = window.innerWidth < 1000;

  const handleChange = (newDateRange: DateRange<DateTime>) => {
    if (dateRangeValidation(newDateRange)) {
      if (newDateRange === selectedDateRange) return;
      let date1: any = newDateRange[0];
      let date2: any = newDateRange[1];
      let diff: any = date2.diff(date1, "days").days;
      if (diff === 196 && date2 === maxDate) {
        setSelectedDateRange(newDateRange);
      }
      else if (diff > 180) {
        const adjustedDateRange: DateRange<DateTime> = [newDateRange[0], getDaysAfter(newDateRange[0], 180)]
        setSelectedDateRange(adjustedDateRange);
        enqueueSnackbar({
          variant: 'warning',
          message: `180 days limit exceeded, changing the date range to ${adjustedDateRange[0]?.toFormat(numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') + ' - ' + adjustedDateRange[1]?.toFormat(numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}`,
          persist: false
        })
      }
      else {
        setSelectedDateRange(newDateRange);
      }
    }
  }


  return (
    <>
      <IconButton
        aria-label="DateRange"
        color="primary"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        disabled={!dateRangeReady}
        sx={{ backgroundColor: isPopoverOpen ? "#0958ff0a" : "#FFFFFF00" }}
      >
        {loading ? <CircularProgress size={24} /> : <DateRangeIcon />}
      </IconButton>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginRight: "44px", top: "40px" }}
      >
        <div className={styles.Container}>
          <div className={styles.Title}>{t('DateSettings-titleText')}</div>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            localeText={{ start: 'From:', end: 'To:' }}
          >
            {
              isMobile ?
                <MobileDateRangePicker
                  value={selectedDateRange}
                  format={numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                  minDate={startDate ?? undefined}
                  maxDate={maxDate ?? undefined}
                  open={calendarOpen}

                  onChange={handleChange}
                  onClose={() => {
                    handleCloseMobile();
                  }}
                  onAccept={(value) => {
                    //somehow this is triggered when clear is clicked we are using this to set the value back when we didn't open the popover
                    if (value[0] === null && value[1] === null) {
                      clear = true;
                    }
                  }}
                  onError={(error) => {
                    setIsError(error)
                  }}
                  slots={{
                    shortcuts: CustomRangeShortcuts,
                    toolbar: CustomPickerToolbar
                  }}
                  slotProps={{
                    textField: ({ position }) => ({
                      size: 'small',
                      positon: 'start',
                      helperText: position === 'start' ? startErrorMessage : toErrorMessage,
                      onFocus: () => {
                        setCalendarOpen(true);
                      }
                    }),
                    shortcuts: {
                      items: shortcutsItems,
                      sx: {
                        overflowX: 'scroll'
                      }
                    },
                    toolbar: {
                      hidden: false,
                    },
                    actionBar: {
                      hidden: false,
                      actions: ['accept', 'clear']
                    },
                    layout: {
                      isLandscape: false,
                      sx: {
                        display: 'block',
                        '--items-to-show-in-one-row': 1,
                      },
                    }
                  }}
                  calendars={2}
                />
                :
                <DesktopDateRangePicker
                  value={selectedDateRange}
                  format={numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                  // minDate={startDate ?? undefined}
                  maxDate={maxDate ?? undefined}
                  open={calendarOpen}
                  onChange={handleChange}
                  onError={(error) => {
                    setIsError(error)
                  }}
                  slots={{
                    shortcuts: CustomRangeShortcuts,
                  }}
                  slotProps={{
                    textField: ({ position }) => ({
                      size: 'small',
                      positon: 'start',
                      helperText: position === 'start' ? startErrorMessage : toErrorMessage,
                      onFocus: () => {
                        setCalendarOpen(true);
                      }
                    }),
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    popper: {
                      placement: "bottom-end",
                    }
                  }}
                />
            }
          </LocalizationProvider>
        </div>
      </Popover>
    </>
  );
}