import { useState, useEffect } from 'react';
import { http } from '../../api/http';

export function useSetMfa(userid: string) {
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const setMfa = async (key: string, mfa: string) => {
        setError(null);
        setLoading(true);
        try {
            const response = await http.get(`/api/setUpMFA?userid=${userid}&key=${key}&mfa=${mfa}`, {
                validateStatus: null, // stops interceptor to handle error
            });

            if (response.status > 299) {
                throw new Error(response.data)
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { error, loading, setMfa };
}