import * as React from 'react';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import styles from './style.module.css';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useRowContext from '../../../../../hooks/ContextHooks/useRowContext';
import { EChartsOption } from 'echarts';
import moment from 'moment';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { useSelector } from 'react-redux';
import { XAXisOption } from 'echarts/types/dist/shared';
import { RootState } from '../../../../../stores/store';
import { useTooltipFormatterWithDateFormat } from '../../../../../utils/Echarts';

export interface IRowProps {
}

export default function Row(props: IRowProps) {

	const { RowDataGraphSeries, loading } = useRowContext();
	const { dateRange } = useFieldDetailContext();
	const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
	const roles = useSelector((state: RootState) => state.user.role);
	const isAdmin = roles.find((role: string) => role === "Goanna Administrators") ? true : false;
	const formatter = useTooltipFormatterWithDateFormat({
		"Water Level": '%',
		"Voltage": 'V',
		"Raw Millivolt": 'mV'
	})
	const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            textStyle: {
                fontWeight: 'normal'
            },
			formatter
        },
		legend: {
			show: true,
		},
		xAxis: {
            type: 'time',
            boundaryGap: false,
            min: dateRange[0]?.toISODate(),
            max: dateRange[1]?.toISODate(),
            axisPointer: {
                label: {
                    formatter: function (param: any) {
                        const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
                        return moment(param.value).format(dateTimeFormat);
                    }
                }
            },
        } as XAXisOption,
		dataZoom: {
            type: 'slider'
        },
        grid: {
            left: 90,
            right: 90,
        },
		yAxis: [
		  {
			type: "value",
			name: `Water Level`,
			nameLocation: 'middle',
			nameGap: 40,
			min: 0,
			max: 100,
			nameTextStyle: {
				fontSize: '1rem',
				color: '#000000',
			},
			axisLabel: {
				formatter: '{value}%'
			  }
		  },
		  {
			type: "value",
			name: "Voltage (V)",
			nameLocation: 'middle',
			nameGap: 40,
			nameTextStyle: {
				fontSize: '0.8rem',
				color: '#000000',
			},
			show: isAdmin
		  },
		  {
			type: "value",
			name: "Raw Millivolt (mV)",
			nameLocation: 'middle',
			nameGap: 20,
			nameTextStyle: {
				fontSize: '0.8rem',
				color: '#000000',
			},
			axisLabel: {
				inside: true,
			},
			alignTicks: true,
			show: isAdmin,
			position: 'right',
		  },
		],
		series: [
		  {
			name: "Water Level",
			type: "line",
			data: RowDataGraphSeries === null ? [] : RowDataGraphSeries.series.WaterLevel.data,
			smooth: false,
			showSymbol: true,
			symbolSize: 0.1,
			symbol: 'circle',
			yAxisIndex: 0,
			emphasis: {
				scale: 100,
			},
			itemStyle: {
                color: "#4A90E2",
            },
		  },
		  isAdmin ?
		  {
			name: "Voltage",
			type: "line",
			data: RowDataGraphSeries === null ? [] : RowDataGraphSeries.series.Volts.data,
			smooth: false,
			showSymbol: true,
			symbolSize: 0.1,
			symbol: 'circle',
			yAxisIndex: 1,
			emphasis: {
				scale: 100,
			},
			itemStyle: {
                color: "#000000",
            },
		  } : {},
		  isAdmin ?
		  {
			name: "Raw Millivolt",
			type: "line",
			data: RowDataGraphSeries === null ? [] : RowDataGraphSeries.series.RawDataMillivolt.data,
			smooth: false,
			showSymbol: true,
			symbolSize: 0.1,
			symbol: 'circle',
			yAxisIndex: 2,
			emphasis: {
				scale: 100,
			},
			itemStyle: {
                color: "#D0021B",
            },
		  } : {}
		],
	  };

	return (
		<>
			<FieldDetailPaper width='100%' id="EtcCropWaterUseGraph">
				<FieldDetailHeader backgroundColor='#D9D7D780'>
					<div className={styles.RowHeader}>
						Row Sensor
					</div>
				</FieldDetailHeader>
				<FieldDetailBody className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px' }} loading={loading}>
					<ReactECharts option={option} style={{ height: '500px', width: '100%' }} loading={loading} />
				</FieldDetailBody>
			</FieldDetailPaper>
		</>
	);
}
