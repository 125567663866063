import { CircularProgress, createTheme, IconButton, ThemeProvider } from '@mui/material';
import DraggableItem from './DraggableItem';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { List } from '@mui/material';
import {
    DragDropContext,
    Droppable,
    DropResult
} from '@hello-pangea/dnd';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { setGraphPreference, userState } from '../../../../../stores/Slices/userSlice';
import { gql } from '@apollo/client';
import { secondaryClient } from '../../../../../api/apollo';
import { useAppDispatch, useAppSelector } from '../../../../../stores/hooks';
import { useEffect, useState } from 'react';
import useUserSettings from '../../../../../hooks/ApiHooks/useUserSettings';
import { ReactComponent as SettingIcon } from '../../../../../assets/images/Navbar/ic_setting.svg';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    background: 'linear-gradient(to right, #75B9F0, #1085FD)',
                    width: '10rem',
                    height: '2.5rem',
                    marginTop: '0.3rem',
                    marginBottom: '0.3rem',
                    marginRight: '0.5rem',
                    marginLeft: '0.5rem'
                }
            }
        }
    }
});

export const graphPrefrences: { [key: string]: string } = {
    "sch_cal": "Schedule Calendar",
    "soil_tot": "Soil Total",
    "stress": "Deficit Stress",
    "smp&rootzone": "Soil Moisture Profile and Rootzone",
    "Location": "Location",
    "rainfall": "Rainfall",
    "crop": "Crop",
    "Irrigation": "Irrigation",
    "etc_wu": "Etc Crop Water Use",
    "cdd": "Cumulative Day Degrees",
    "notes": "Notes",
    "row": "Row Sensor"
}

export interface ICustomizationButtonProps {
}

const SET_GRAPH_PREFERENCE = gql(`
mutation ($prefrences: String!) {
  UpdateUserSetting (
    input: {
        graphPreference: $prefrences
      authToken: ${localStorage.getItem("access_token")}
    }
  ) {
    graphPreference
  }
}
`);

const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/*
 * 0 - Not Selected
 * 1 - Selected
 * 2 - RESERVED (for future)
 * 3 - Always Selected
*/

export default function CustomizationButton(props: ICustomizationButtonProps) {

    const { locationDevicesData, deviceData } = useFieldDetailContext();
    const graphPreference = useAppSelector(state => state.user.graphPreference);
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showStressGraph, setShowStressGraph] = useState<boolean>(true);
    const [showEtcGraph, setShowEtcGraph] = useState<boolean>(true);
    const [showRowGraph, setShowRowGraph] = useState<boolean>(true);
    const isPopoverOpen = Boolean(anchorEl);
    const [localItems, setLocalItems] = useState<{ name: string; checked: number; }[]>(graphPreference["ProbeGraphs"]);
    const [hasChanged, setHasChanged] = useState(false);

    const { refreshReduxFromGraphQL } = useUserSettings();

    useEffect(() => {
        setShowStressGraph(!!locationDevicesData.data?.data.value[0].LocationIDCanopy);
    }, [locationDevicesData.data?.data.value[0].LocationIDCanopy]);

    useEffect(() => {
        setShowEtcGraph(!!locationDevicesData.data?.data.value[0].LocationIDSatellite);
    }, [locationDevicesData.data?.data.value[0].LocationIDSatellite]);
    
    useEffect(() => {
        setShowRowGraph(!(deviceData.data?.value[0].DeviceTypeID !== 161));
    }, [deviceData.data?.value[0].DeviceTypeID]);

    useEffect(() => {
        refreshReduxFromGraphQL();
    }, []);

    const id = isPopoverOpen ? 'simple-popover' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // reset the data
    const handleClose = () => {
        setAnchorEl(null);
        if (hasChanged) handleSave();
    };

    // save clicked, save it to redux and graphql
    const handleSave = () => {
        setLoading(true);

        const updatedGraphPreference = {
            ...graphPreference,
            "ProbeGraphs": localItems
        }

        secondaryClient.mutate({
            mutation: SET_GRAPH_PREFERENCE,
            variables: { prefrences: JSON.stringify(updatedGraphPreference) },
        })
            .then(() => dispatch(setGraphPreference(updatedGraphPreference)))
            .catch((e) => console.error(e))
            .finally(() => setLoading(false))
        setAnchorEl(null);
    }

    const onDragEnd = ({ destination, source }: DropResult) => {

        // dropped outside the list
        if (!destination) return;

        const newItems = Array.from(localItems);
        const [reorderedItem] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, reorderedItem);
        setLocalItems(newItems);
        setHasChanged(true);
    };

    const handleItemSelect = (itemName: string) => {
        const itemsClone: typeof localItems = JSON.parse(JSON.stringify(localItems));
        const index = itemsClone.findIndex(item => item.name === itemName)

        if (index === -1) {
            console.error("No Item Found with that id when selecting graphPrefrence");
            return;
        }

        if (itemsClone[index].checked === 3) {
            console.error("Uneditable field when selecting graphPrefrence");
            return;
        }

        itemsClone[index].checked = itemsClone[index].checked === 1 ? 0 : 1;
        setLocalItems(itemsClone);
        setHasChanged(true);
    }

    return (
        <>
            <IconButton
                id="customization-button"
                color="primary"
                aria-controls={isPopoverOpen ? 'customization-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isPopoverOpen ? 'true' : undefined}
                onClick={handleClick}
                sx={{ fontSize: 24, backgroundColor: isPopoverOpen ? "#0958ff0a" : "#FFFFFF00" }}
                disabled={loading}
            >{loading ? <CircularProgress size={24} /> : <SettingIcon filter="invert(23%) sepia(43%) saturate(6539%) hue-rotate(221deg) brightness(100%) contrast(102%)" />}
            </IconButton>
            <Popover
                id={id}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List sx={{ width: '372px', maxHeight: '75vh', maxWidth: 600, bgcolor: 'background.paper' }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-list">
                            {(provided: any) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        localItems.map((element, index) => {
                                            const labelId = `checkbox-list-label-${index}`;

                                            if (
                                                (element.name === "stress" && !showStressGraph)
                                                || (element.name === "etc_wu" && !showEtcGraph)
                                                || (element.name === "row" && !showRowGraph)
                                            ) return;

                                            return <DraggableItem
                                                handleItemSelect={handleItemSelect}
                                                key={element.name}
                                                displayValue={graphPrefrences[element.name]}
                                                internalID={element.name}
                                                labelId={labelId}
                                                index={index}
                                                checked={element.checked}
                                                totalItems={localItems.length}
                                            />
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/* Leaving this here because we might reuse it later for reset to default
                    <ThemeProvider theme={theme}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                onClick={handleSave}
                                disableElevation
                                size='small'
                                sx={{
                                    "&:hover": {
                                        boxShadow: 3,
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </ThemeProvider> */}
                </List>
            </Popover>
        </>
    );
}
