import { TextField } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GraphPageDataCard, { GraphPageDataCardType } from "../../../../../components/GraphPageDataCard/GraphPageDataCard";
import { FieldDetailDataType } from "../../../../../context/FieldDetailsContext/FieldDetailsContext";
import { LocationCropsByLocationIDDataType, usePutLocationCropsByLocationIDData } from "../../../../../hooks/ApiHooks/useLocationCropsByLocationIDData";
import useFieldDetailContext from "../../../../../hooks/ContextHooks/useFieldDetailContext";
import { aestToLocationLocalTimeConverter, AEST_Zone, locationLocalToAESTTimeConverter } from "../../../../../utils/DateConvertor";
import { dateFormatter } from "../../../../../utils/numericConversions";
import styles from './style.module.css';
import { basicTextBoxRegEx } from "../../../../../utils/validations";
import { ValidationError } from "yup";
import usePutLocationsEdit, { LocationsEditDataType, PUT_ROLES_REQUIRED } from "../../../../../hooks/ApiHooks/useLocationsEdit";
import { useAppSelector } from "../../../../../stores/hooks";
import * as yup from "yup";

// TODO: use this hook for other places to replace `isAdmin` variables 
import { AllowedAccess, usePermission } from "react-permission-role";
import { useSnackbar } from "notistack";


const MAX_LOCATION_NAME_CHAR = 100;


interface ILocationCard { }

export default function LocationCard(props: ILocationCard) {
  const { t } = useTranslation("CropCard");
  const { t: t2 } = useTranslation("LocationCard");

  const { numericSystem } = useAppSelector(state => state.user);
  const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();
  const [hasChanged, setHasChanged] = useState({ locationEditData: false, LocationCropsByLocationIDData: false });
  const [putLocationEditData, { error }] = usePutLocationsEdit();

  let latestIndex: number = 0;
  let latestStartDate: string | null = null;

  FieldDetailContext.LocationCropsByLocationIDData.data?.data.value.forEach(
    function (value, index) {
      if (latestStartDate == null || DateTime.fromISO(latestStartDate, { zone: AEST_Zone }) < DateTime.fromISO(value.StartDate, { zone: AEST_Zone })) {
        latestStartDate = value.StartDate;
        latestIndex = index
      }
    });



  const TextBoxSchema = useMemo(() => yup.object({
    "Location Name": yup
      .string()
      .min(1, t2("LocationCard-Errors-LocationName-minChar"))
      .max(MAX_LOCATION_NAME_CHAR, t2("LocationCard-Errors-LocationName-maxChar", { count: MAX_LOCATION_NAME_CHAR }))
      .test({
        test: (value) => {
          if (!value) return true;
          let errorCharList = new Set<string>();
          for (let char of value) {
            if (!basicTextBoxRegEx.test(char)) {
              errorCharList.add(char);
            }
          }
          if (errorCharList.size > 0)
            return new ValidationError(t2("LocationCard-Errors-LocationName-invalidChar", { chars: Array.from(new Set(errorCharList)) }));
          return true;
        }
      })
  }), [t2]);

  const validateLocationName = useCallback((locationName: string): [boolean, string?] => {
    try {
      TextBoxSchema.validateSync({ "Location Name": locationName })
    }
    catch (e: any) {
      return [false, e.message]
    }
    return [true]
  }, [TextBoxSchema])

  const soilProbesData = FieldDetailContext.soilProbesData.data?.data.value[0] ?? {};
  const locationDevicesData = FieldDetailContext.locationDevicesData.data?.data.value[0] ?? {};
  const LocationCrop = FieldDetailContext.LocationCropsByLocationIDData.data?.data.value[latestIndex] ?? {} as LocationCropsByLocationIDDataType
  const LocationsEdit = FieldDetailContext.LocationsEdit.data?.data.value[0] ?? {} as LocationsEditDataType;
  const [locationName, setLocationName] = useState<string>(locationDevicesData.LocationDescription)
  const { enqueueSnackbar } = useSnackbar();

  const [endDate, setEndDate] = useState(
    aestToLocationLocalTimeConverter(LocationCrop.EndDate, soilProbesData.GMTDifference)
  )

  useEffect(() => {
    setEndDate(
      aestToLocationLocalTimeConverter(LocationCrop.EndDate, soilProbesData.GMTDifference)
    );
  }, [LocationCrop, soilProbesData]);

  useEffect(() => {
    setLocationName(locationDevicesData.LocationDescription);
  }, [locationDevicesData.LocationDescription]);

  const [putLocationCropsByLocationIDData, { loading: LocationCropsPUTLoading }] = usePutLocationCropsByLocationIDData();
  const LocationNameValidated = useMemo(() => validateLocationName(locationName), [locationName]);

  const handleSubmit = useCallback(() => {
    // is in error
    if (!LocationNameValidated[0]) return;

    if (hasChanged.LocationCropsByLocationIDData) {
      putLocationCropsByLocationIDData(LocationCrop.LocationCropID, FieldDetailContext.locationID, {
        CropID: LocationCrop.CropID,
        LocationCropID: LocationCrop.LocationCropID,
        LocationID: FieldDetailContext.locationID,
        PlantDate: LocationCrop.PlantDate,
        EndDate: moment(locationLocalToAESTTimeConverter(endDate, soilProbesData.GMTDifference)).format("yyyy-MM-DDT00:00:00"),
        SalePrice: LocationCrop.SalePrice,
        Yield: LocationCrop.Yield
      },
        LocationCrop,
        () => {
          FieldDetailContext.LocationCropsByLocationIDData.refetch();
          FieldDetailContext.LocationCropReportSummaryByLocationIDData.refetch();
        })
    }
    if (hasChanged.locationEditData) {
      putLocationEditData(
        { LocationDescription: locationName.trim() },
        LocationsEdit,
        (data) => {
          enqueueSnackbar({
            variant: "success",
            persist: false,
            message: "Location Name saved!",
            autoHideDuration: 3000
          })
          setTimeout(() => {
            FieldDetailContext.soilProbesData.refetch();
          }, 300);
        },
        (error, variables, context) => {
          enqueueSnackbar({
            variant: "error",
            persist: false,
            message: error.data.error.message,
            autoHideDuration: 3000
          })
        })
      // TODO: add loading
    }
    setHasChanged({ locationEditData: false, LocationCropsByLocationIDData: false });
  }, [LocationsEdit, LocationCrop, locationName, endDate, hasChanged, setHasChanged, LocationNameValidated]);

  const loading = FieldDetailContext.soilProbesData.isLoading || FieldDetailContext.locationDevicesData.isLoading || LocationCropsPUTLoading;

  // useEffect(() => {
  //   if (error)
  //     enqueueSnackbar({
  //       variant: "error",
  //       persist: false,
  //       message: error.message,
  //       autoHideDuration: 3000
  //     })
  // }, [error]);

  const LocationCard: GraphPageDataCardType = {
    title: "Location",
    items: [
      {
        title: "Name",
        action: <AllowedAccess roles={PUT_ROLES_REQUIRED} renderAuthFailed={<>{locationName}</>}>
          <TextField
            error={!LocationNameValidated[0]}
            size="small"
            onChange={(e) => {
              setLocationName(e.target.value);
              setHasChanged(prevState => ({ ...prevState, locationEditData: true }));
            }}
            helperText={validateLocationName(locationName)[1]}
            value={locationName}
          />
        </AllowedAccess>
      },
      { title: "Organization", action: locationDevicesData.AccountName },
      { title: "Time Zone", action: soilProbesData.TimeZoneCode },
      { title: "Start Date", action: dateFormatter(numericSystem, aestToLocationLocalTimeConverter(locationDevicesData.StartDate, soilProbesData.GMTDifference)) },
      {
        title: t("CropCard-EndDate"), action: <LocalizationProvider dateAdapter={AdapterLuxon} >
          <DesktopDatePicker
            onChange={(e) => {
              setHasChanged(prevState => ({ ...prevState, LocationCropsByLocationIDData: true }));
              setEndDate(e?.toString() ?? null)
            }}
            format={numericSystem == "M" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
            value={endDate ? DateTime.fromISO(endDate) : null}
            className={styles["text-field-width"]}
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
          />
        </LocalizationProvider>
      },
      { title: "Start Date Canopy", action: dateFormatter(numericSystem, aestToLocationLocalTimeConverter(locationDevicesData.StartDateCanopy, soilProbesData.GMTDifference)) },
      { title: "End Date Canopy", action: dateFormatter(numericSystem, aestToLocationLocalTimeConverter(soilProbesData.EndDateCanopy, soilProbesData.GMTDifference)) },
      { title: "Last Update", action: dateFormatter(numericSystem, soilProbesData.DateTime, true) },
      { title: "Active", action: locationDevicesData.Active ? "True" : "False" },
    ]
  };

  return <GraphPageDataCard
    title={LocationCard.title}
    items={LocationCard.items}
    showSaveButton={Object.values(hasChanged).some(a => a)}
    onSubmit={handleSubmit}
    loading={loading}
    id="LocationCard"
  />
}