import { useState, useEffect, useRef } from 'react';
import { http } from '../../api/http';

export function useGetMfaUri(userid: string) {
    const [mfaUri, setMfaUri] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            controller.current?.abort();
        };
    }, []);

    const abort = () => {
        controller.current?.abort();
    }

    const controller = useRef<AbortController | null>(null);

    const getMfaUri = async () => {
        controller.current = new AbortController();
        setMfaUri(null);
        setError(null);
        setLoading(true);
        try {
            let response = await http.get(`/api/getNewTotpURI?userid=${userid}`, {
                validateStatus: null, // stops interceptor to handle error
                signal: controller.current.signal
            });

            if (response.status === -1) {
                return
            }

            if (response.status > 299) {
                throw new Error(response?.data?.Message)
            }

            setMfaUri(response.data);
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { mfaUri, error, loading, getMfaUri, abort };
}
