import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

const url = process.env.REACT_APP_AXIOS_URL;

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  // "Access-control-request-headers" : "authorization",
  // "Access-Control-Allow-Credentials": false,
  // "X-Requested-With": "XMLHttpRequest"

};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = JSON.parse(localStorage.getItem("access_token") ?? '""');

    if (token !== '' && config.headers !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    throw new Error(error as string);
  }
};

class Http {
  private instance: AxiosInstance | null = null;

  //getter, get the instance through the http()
  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {

    const http = axios.create({
      baseURL: url,
      headers,
      withCredentials: false,
    });

    http.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    http.interceptors.response.use(response => response, error => {
      if (error.code === "ERR_CANCELED")
        return { status: -1, Message: "ABORTED" }

      const { response } = error;

      if (response.status === 401) {
        window.location.assign("/pages/401");
      } else if (response.status === 403) {
        window.location.assign("/pages/403");
      }
      else if (response.status === 404) {
        window.location.assign("/pages/404");
      }
      else {
        if (response.data.Message)
          throw new Error(response.data.Message);
        throw error;
      }
    });

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }
}

export const http = new Http();